import React, { useState } from 'react'
import Header from "./Header"
import { Link, useNavigate } from 'react-router-dom'
import playstore from '../Assets/PlayStore Button.png'
import appStore from '../Assets/AppStore Button.png'
import BaseUrl from '../BaseUrl'
import axios from 'axios'
import swal from "sweetalert";


export default function CompanySignUp() {

    const [companyName, setCompanyName] = useState("");
    const [companyWebsite, setCompanyWebsite] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [cPassword, setCPassword] = useState("");
    const [loading, setLoading] = useState(false);

    const navigate = useNavigate()

    const userSignUp = async (e) => {
        e.preventDefault();
        setLoading(true)
        const url = BaseUrl() + "api/user/company-register";
        const data = {
            company_name: companyName,
            company_website: companyWebsite,
            email: email,
            password: password,

        };
        if (password !== cPassword) {
            return (
                swal("Password and conferm password is not match")
            )
        }
        try {
            const response = await axios.post(url, data);
            if (response.data.ResponseCode === 200) {
                localStorage.setItem("authorization", response.data.tempToken);
                localStorage.setItem("profile", JSON.stringify(response.data.Response));

                navigate("/register-otp-verify", {
                    state: {
                        data: data
                    }
                });
            }
            setLoading(false);
            if (response.data.ResponseCode === 500) {
                swal(response.data.ResponseMessage);
            }
        } catch (error) {
            swal(error.response.data.ResponseMessage);
        }
    }

    return (
        <div>
            <Header />
            <form onSubmit={userSignUp}>
                <div className="w-full max-w-2xl mx-auto md:mt-96 ">
                    <div className="bg-white shadow-md z-10 mx-3 absolute rounded px-12 py-16 sm:-mt-96  md:-mt-64 mt-20 w-11/12 md:w-4/5 lg:w-2/5">
                        <div className="mb-4">
                            <input
                                className=" appearance-none border-b-2 text-gray-500 border-gray-500 w-full py-1 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                type="text"
                                placeholder="Company Name"
                                value={companyName}
                                onChange={(e) => {
                                    setCompanyName(e.target.value);
                                }}
                            />
                        </div>
                        <div className="mb-4">
                            <input
                                className=" appearance-none border-b-2 text-gray-500 border-gray-500 w-full py-1 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                type="text"
                                placeholder="Company Website URL"
                                value={companyWebsite}
                                onChange={(e) => {
                                    setCompanyWebsite(e.target.value);
                                }}
                            />
                        </div>
                        <div className="mb-4">
                            <input
                                className=" appearance-none border-b-2 text-gray-500 border-gray-500 w-full py-1 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                type="text"
                                placeholder="Email Id"
                                value={email}
                                onChange={(e) => {
                                    setEmail(e.target.value);
                                }}
                            />
                        </div>
                        <div className="mb-6">
                            <input
                                className="appearance-none border-b-2 border-gray-500 w-full py-1 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                type="password"
                                placeholder="Create Password"
                                value={password}
                                onChange={(e) => {
                                    setPassword(e.target.value);
                                }}
                            />
                        </div>
                        <div className="mb-6">
                            <input
                                className="appearance-none border-b-2 border-gray-500 w-full py-1 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                type="password"
                                placeholder="Confirm Password"
                                value={cPassword}
                                onChange={(e) => {
                                    setCPassword(e.target.value);
                                }}
                            />
                        </div>
                        <div className='grid justify-items-end'>

                            {/* <Link>
                                Forgot password?
                            </Link>*/}

                        </div>
                        <div className='grid justify-items-center  mt-4'>
                            <button onClick={userSignUp} className='bg-gradient-to-r to-cyan-500 from-blue-500 hover:bg-blue-700 text-white md:text-2xl font-bold py-3 mb-2 px-20 md:px-28 rounded-full '>
                                Sign Up
                            </button>
                            <div className='font-bold  space-y-1 text-sm text-gray-500'>
                                {/*<p >Click hare! Frorgot Password</p>*/}
                                <div className='flex'>
                                    <p >I have an account?</p>

                                    <Link to="/login" className='text-blue-500 '>
                                        Sign In
                                    </Link>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
            <br />
            {/* <div className="text-sm text-white py-1 w-full bg-sky-400 absolute lg:mt-52 md:mt-[600px]">
                      <div className="flex sm:ml-60">
                       <img src={appStore} />
                       <img src={playstore} className="ml-2" />
                       <div align="left" className="flex space-x-16 md:space-x-1 my-auto w-64 pl-5 mr-28 ml-auto">
                       <Link>Home</Link>
                       <Link>Blog</Link>
                       <Link>About</Link>
                         
                       </div>
                      </div>
                      <div align="left" className="flex space-x-16 bg-white my-auto w-64 pl-5 mr-28 ml-auto">
                      </div>
                       <p className="ml-60 text-start">&#169;2023- Serendipty| All right reserved</p>

                       

                     </div> */}
        </div>
    )
}