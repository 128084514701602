import React, { useState, useEffect } from "react"
import HOC from "./HOC"
import axios from "axios";
import BaseUrl from "../BaseUrl"
import "./side.css"
import swal from "sweetalert";
import { useLocation } from "react-router-dom"



const AddGoal = () => {
    const [softSkill, setSoftSkill] = useState([]);
    const [hardSkill, setHardSkill] = useState([]);
    const [startDate, setStartDate] = useState("")
    const [endDate, setEndDate] = useState("")
    const [arr, setArr] = useState([])

    const location = useLocation()

    console.log("locationhome>>", location)
    // var arr = []

    const emptyState = async () => {
        setStartDate("")
        setEndDate("")
        setArr(arr => [])
    }

    const validateEmployee = () => {
        const errors = {};
        if (arr == "") {
            swal('Please Select Skill')
            return
        }
    };
    const token = localStorage.getItem("authorization")
    var auth = {
        headers: {
            authorization: `bearer ${token}`,
        },
    };
    const addItem = async (item) => {
        console.log("item", arr.indexOf(item._id))
        if (arr.indexOf(item._id) > -1) {
            arr.splice(arr.indexOf(item._id), 1);
            document.getElementById(item._id).classList.remove('active')
        } else {
            arr.push(item._id)
            document.getElementById(item._id).classList.add('active')
        }
        console.log("arr>>", arr)
    }


    const addGoal = async () => {
        validateEmployee()
        const url = BaseUrl() + "api/goal/add-goal"
        const data = {
            start_Date: startDate,
            end_Date: endDate,
            skilId: arr
        }
        if (data.skilId.length == 0) {
            swal("Please  select skill")
            return
        }
        if (data.start_Date == "") {
            swal("Please select Start Date")
            return
        }
        if (data.start_Date == "") {
            swal("Please select End Date")
            return
        }
        try {
            const res = await axios.post(url, data, auth)
            console.log(res)

            if (res.data.ResponseCode == 200) {
                emptyState()
                swal(res.data.ResponseMessage, {
                    icon: "success"
                })
            } else {
                swal("Something went wrong")
            }
        } catch (error) {
            console.log(error)
        }
    }



    const getAllSkill = async () => {
        const url = BaseUrl() + "api/skill/get-skill";
        try {
            const res = await axios.get(url);
            console.log("res", res)
            if (res.data.ResponseCode == 200) {
                setSoftSkill(res.data.Response.soft_skill);
                setHardSkill(res.data.Response.hard_skill);
            }
        } catch (error) {
            console.log(error)
            throw error
        }
    };
    useEffect(() => {
        getAllSkill();
    }, []);


    return (
        <>
            <div className="flex justify-center">
                <div className=" lg:w-2/3 md:w-11/12 md:ml-8 sm:w-full h-full px-16  py-8 flex shadow-lg bg-white border-t border-r border-l rounded-t">


                    <div className="text-sky-400 ml-auto">
                        <div align="right" className="text-sky-400">
                            Select Time Duration
                        </div>
                        <div className="flex">



                            <div className="text-sky-400 ml-auto text-sm ">
                                Start Date
                                <input
                                    type="date"
                                    className="bg-transparent px-2 focus:outline-none focus:shadow-outline "
                                    value={startDate}
                                    onChange={(e) => {
                                        setStartDate(e.target.value)
                                    }}
                                />
                            </div>

                            <div className="text-sky-400 ml-auto text-end text-sm ">
                                End Date
                                <input
                                    type="date"
                                    className="px-2 bg-transparent focus:outline-none focus:shadow-outline "
                                    value={endDate}
                                    onChange={(e) => {
                                        setEndDate(e.target.value)
                                    }}
                                />
                            </div>
                        </div>
                    </div>


                </div>


            </div>
            <div className="flex-wrap flex justify-center sm:mb-96">

                <div className="mb-4 lg:w-2/3 md:w-11/12 sm:w-full text-start md:ml-8 w-full h-full pl-8  py-8  shadow-lg bg-white text-center ">
                    {/* <h1 className="text-sky-500 grid grid-cols-10">Soft Skill</h1> */}
                    <p className="text-sky-400 text-start  text-lg">Hard Skils</p>
                    <div className="grid  lg:grid-cols-6 grid-cols-3  text-center  flex-wrap border-b gap-2 ">
                        {hardSkill.map((item) => {
                            return (
                                <div key={item._id} className="rounded-full text-center text-sm" role="button">
                                    <img id={item._id} className={'rounded-full  h-20 border p-4 text-center my-4 '}
                                        src={BaseUrl() + item.image}
                                        onClick={e => addItem(item)}
                                    />
                                    <p className='text-center md:mr-12 mr-8'>{item.skill}</p>
                                </div>
                            )
                        })}
                    </div>
                    <div className="text-sky-400  text-lg content-center">
                        Soft Skils
                    </div>

                    <div className="grid  lg:grid-cols-6 grid-cols-3  text-center  flex-wrap border-b gap-2 ">
                        {softSkill.map((item) => {
                            return (
                                <div key={item._id} className="rounded-full text-center text-sm " role="button">
                                    <img id={item._id} className="rounded-full hover:bg-sky-400 h-20 border p-4 text-center my-4" src={BaseUrl() + item.image}
                                        onClick={e => {
                                            addItem(item)
                                        }}
                                    />
                                    <p className='text-center md:mr-12 mr-8'>{item.skill}</p>
                                </div>
                            )
                        })}
                    </div>
                    <div align="center" >
                        <button align="right" onClick={addGoal} className="bg-gradient-to-r mx-auto to-cyan-500 from-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-20 rounded-full mt-20">
                            Add
                        </button></div>
                </div>
            </div>
        </>
    )
}
export default HOC(AddGoal)