import React, { useState, useEffect } from "react";
import HOC from "../component/HOC";
import BaseUrl from "../BaseUrl";
import axios from 'axios'
import { BiDotsVerticalRounded } from "react-icons/bi";
import { Menu } from '@headlessui/react'
import { saveAs } from "file-saver";

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

const Report = () => {

    const [data, setData] = useState([]);

    const getReport = async () => {
        const token = localStorage.getItem("authorization")
        var auth = {
            headers: {
                authorization: `bearer ${token}`,
            },
        };
        const url = BaseUrl() + "api/goal/get-my_report";
        try {
            const res = await axios.get(url, auth);
            if (res.data.ResponseCode == 200) {
                setData(res.data.Response);

            } else {
                // swal(res.data.ResponseMessage,{
                //   button:false
                // });
            }
        } catch (error) {
            console.log(error);
        }
    };

    const dowenloadFile = (row) => {
        console.log(row)
        window.addEventListener("storage", event => {
            const { newValue, oldValue, key } = event;

            console.log("event", event);
            if (key !== "blobUrl") return;

            if (newValue === oldValue) return;

            const blobUrl = row
            console.log(row);

            console.log(blobUrl);
            saveAs(blobUrl, row);
        });

        return () => {
            window.removeEventListener("storage", function () { });
        };
    };






    useEffect(() => {
        getReport();
    }, []);


    return (
        <>
            {/*<h1>Report Page</h1>*/}

            {data.map((item) => {
                return (

                    <div key={item.id} className="flex justify-center">
                        <div className=" border-b-2 border-slate-500  md:w-2/3 w-11/12   bg-sky-400 mb-6 rounded-xl bg-white hover:bg-sky-400">
                            <div class="grid grid-cols-2 lg:gap-96 md:gap-72 gap-60   p-4 text-white">
                                {item.reportName}
                                <div>

                                    <Menu as="div" className="relative inline-block text-left">
                                        <div>
                                            <Menu.Button >
                                                <BiDotsVerticalRounded className="text-white text-4xl" />
                                            </Menu.Button>
                                        </div>

                                        <Menu.Items className="absolute right-0 z-10 -mt-12 w-48  origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                            <div className="py-1">
                                                <Menu.Item>
                                                    {({ active }) => (
                                                        <a
                                                            href={BaseUrl() + "api/goal/generate-pdf/" + item.goalId}
                                                            className={classNames(
                                                                active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                                'block px-4 py-2 text-sm'
                                                            )}
                                                        >
                                                            View
                                                        </a>
                                                    )}
                                                </Menu.Item>
                                                <Menu.Item>
                                                    {({ active }) => (

                                                        <a
                                                            onClick={dowenloadFile(item.pdf)}
                                                            className={classNames(
                                                                active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                                'block px-4 py-2 text-sm',

                                                            )}
                                                        >
                                                            Dowenload
                                                        </a>
                                                    )}
                                                </Menu.Item>

                                                <Menu.Item>
                                                    {({ active }) => (
                                                        <a
                                                            className={classNames(
                                                                active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                                'block px-4 py-2 text-sm'
                                                            )}
                                                        >
                                                            Share
                                                        </a>
                                                    )}
                                                </Menu.Item>

                                            </div>
                                        </Menu.Items>
                                    </Menu>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            })}
        </>
    )
}

export default HOC(Report)
