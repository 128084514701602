
import React from 'react'
import { Link, NavLink } from 'react-router-dom'
import playstore from '../Assets/PlayStore Button.png'
import appStore from '../Assets/AppStore Button.png'

export default function Footer(props) {

    return (
        <>
         <div className="text-sm text-white  pb-2 pt-2 w-full bg-sky-400 mt-72 absolute ">
                <div className="flex ml-60">
                <a href="https://apps.apple.com/us/app/bsi-blue-sky-index/id6468769002" target="_blank">  <img src={appStore} /></a>
                <a href ="https://play.google.com/store/apps/details?id=com.app.serendipity&pli=1" target="_blank">  <img src={playstore} className="ml-2" /></a>
                    <div align="left" className="flex space-x-16 my-auto w-64 pl-5 mr-28 ml-auto">
                        <Link>Home</Link>
                        <Link>Blog</Link>
                        <Link>About</Link>

                    </div>
                </div>
                <div align="left" className="flex space-x-16 bg-white my-auto w-64 pl-5 mr-28 ml-auto">
                </div>
                <p className="ml-60 text-start">&#169;2023- Serendipty| All right reserved</p>
            </div>

        </>
    )
}