import React, { useState } from 'react';
import Header from './Header';
import BaseUrl from '../BaseUrl';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import swal from 'sweetalert';
import Footer from './Footer';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const [errors, setErrors] = useState({
    email: '',
    password: '',
  });

  const handleValidation = () => {
    let isValid = true;
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

    if (!emailRegex.test(email)) {
      setErrors((prevErrors) => ({ ...prevErrors, email: 'Invalid email address' }));
      isValid = false;
    } else {
      setErrors((prevErrors) => ({ ...prevErrors, email: '' })); 
    }

    if (password.length < 6) {
      setErrors((prevErrors) => ({ ...prevErrors, password: 'Password must be at least 6 characters' }));
      isValid = false;
    } else {
      setErrors((prevErrors) => ({ ...prevErrors, password: '' })); 
    }

    return isValid;
  };
 

  const userLogin = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (handleValidation()) {
      const url = BaseUrl() + 'api/user/signin';

      const data = {
        email: email,
        password: password,
      };

      try {
        const response = await axios.post(url, data);
        console.log("response",response)

        if (response.data.ResponseCode === 200) {
          localStorage.setItem('authorization', response.data.token);
          localStorage.setItem('profile', JSON.stringify(response.data.Response));
          navigate('/home', {
            state: {
              data: response.data.Response,
            },
          });
        }
      

        if (response.data.ResponseCode === 400) {
          // swal(response.data.ResponseMessage);
          toast.error(response.data.ResponseMessage);
        }
      } catch (error) {
        // swal(error.response.data.ResponseMessage);
        toast.error(error.response.data.ResponseMessage);
      }
    }

    setLoading(false);
  };

  return (
    <div>
      <Header />
      <ToastContainer />
      <form onSubmit={userLogin}>
        <div className="max-w-2xl mx-auto md:mt-[408px]">
          <div className="bg-white shadow-md z-10 mx-3 absolute rounded px-12 py-16 sm:-mt-96 md:-mt-64 mt-20 w-11/12 md:w-4/5 lg:w-2/5">
            <div className="mb-4">
              <input
                type="text"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                  setErrors((prevErrors) => ({ ...prevErrors, email: '' }));
                }}
                placeholder="Email Id"
                className={`appearance-none border-b-2 w-full py-2 px-3 border-gray-500 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${
                  errors.email && 'border-red-500'
                }`}
              />
              <div className="mr-auto text-left px-2">
                {errors.email && <span className="text-red-500 ">{errors.email}</span>}
              </div>
            </div>
            <div className="">
              <input
                type="password"
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value);
                  setErrors((prevErrors) => ({ ...prevErrors, password: '' }));
                }}
                placeholder="Password"
                className={`appearance-none border-b-2 w-full border-gray-500 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${
                  errors.password && 'border-red-500'
                }`}
              />
              <div className="mr-auto text-left px-2">
                {errors.password && <span className="text-red-500">{errors.password}</span>}
              </div>
            </div>
            <div className="grid justify-items-end">
              <Link to="/forgot-pass">Forgot password?</Link>
            </div>
            <div className="grid justify-items-center mt-5">
              <button className="bg-gradient-to-r to-cyan-500 from-blue-500 hover:bg-blue-700 text-white text-2xl font-bold py-3 mb-2 px-20 md:px-28 rounded-full">
                Login
              </button>
              <div className="font-bold space-y-1 text-sm text-gray-500">
                <p>Click here! Forgot Password</p>
                <div className="flex">
                  <p>Don't have an account?</p>
                  <Link to="/signup" className="text-blue-500">
                    Sign Up
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>

      <Footer />
    </div>
  );
}
