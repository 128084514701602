import React, { useState, useEffect } from "react";
import axios from 'axios';
import HOC from "../component/HOC";
import BaseUrl from "../BaseUrl";

function PlanCard() {
  const [plan, setPlan] = useState([]);

  const getPlan = async () => {
    const token = localStorage.getItem("authorization");
    const auth = {
      headers: {
        authorization: `Bearer ${token}`,
      },
    };
    const url = `${BaseUrl()}api/user/plan_list`;
    try {
      const res = await axios.get(url, auth);
      if (res.data.ResponseCode === 200) {
        setPlan(res.data.planList);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getPlan();
  }, []);

  return (
    <>
      <div className="container flex flex-wrap pt-4 pb-10 m-auto mt-6 md:mt-15 lg:px-12 xl:px-16">
        <div className="w-full px-0 lg:px-4">
          <h2 className="px-12 text-base font-bold text-center md:text-2xl text-blue-700">
            Choose your plan
          </h2>
          <p className="py-1 text-sm text-center text-blue-700 mb-10">
            It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.
          </p>

          <div className="flex flex-wrap items-center justify-center py-4 pt-0">
            {plan.map((planItem) => (
              <div className="w-full p-4 md:w-1/2 lg:w-1/4" key={planItem.id}>
                <label className="flex flex-col rounded-lg shadow-lg group relative cursor-pointer hover:shadow-2xl">
                  <div className="w-full px-4 py-6 rounded-t-lg card-section-1 bg-white">
                    <h3 className="mx-auto text-base font-semibold text-center underline text-blue-500 group-hover:text-gray-500">
                      {planItem.type}
                    </h3>
                    <p className="text-5xl font-bold text-center group-hover:text-gray-500 text-blue-500">
                      ${planItem.price.toFixed(2)}
                    </p>
                    <p className="text-xs text-center uppercase group-hover:text-gray-500 text-blue-500">
                      {planItem.users} Users
                    </p>
                  </div>
                  <div
                    className="flex flex-col items-center justify-center w-full h-full py-6 rounded-b-lg bg-blue-500"
                  >
                    <p className="text-xl text-white">
                      1 YRS
                    </p>
                    <button className="w-5/6 py-2 mt-2 font-semibold text-center uppercase bg-white border border-transparent rounded text-blue-500">
                      Get Started
                    </button>
                  </div>
                </label>
                <div className="text-center">
                
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}

export default HOC(PlanCard);
