import React, { useState } from 'react'
import Header from "./Header"
import BaseUrl from '../BaseUrl'
import axios from 'axios'
import { Link, useNavigate, useLocation } from 'react-router-dom'
import swal from "sweetalert";
import playstore from '../Assets/PlayStore Button.png'
import appStore from '../Assets/AppStore Button.png'
export default function OtpVerify() {
    const location = useLocation()
    console.log("location", location)
    const [otp, setOtp] = useState("");


    const navigate = useNavigate()

    const sendOtp = async (e) => {
        e.preventDefault();
        const token = localStorage.getItem("authorization")
        var auth = {
            headers: {
                authorization: `bearer ${token}`,
            },
        };
        const url = BaseUrl() + "api/user/verify-otp";
        const data = {
            otp: otp,

        };
        try {
            const response = await axios.post(url, data, auth);
            console.log(response)
            if (response.status === 200) {
                navigate("/change-password");
            }
            else {
                swal(response.data.message);
            }
        } catch (error) {
            swal("Email Not found");
        }
    };
    return (
        <div>
            <Header />
            <form onSubmit={sendOtp}>
                <div className="w-full max-w-2xl mx-auto md:mt-96 ">
                    <div className="bg-white shadow-md z-10 absolute rounded px-12 py-16 sm:-mt-96 ml-3 md:-mt-64 md:w-4/5 lg:w-2/5">
                        <div className="mb-4">
                            <input
                                className="placeholder-black  appearance-none border-b-2 border-gray-500 w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                type="text"
                                value={otp}
                                required
                                onChange={(e) => {
                                    setOtp(e.target.value);
                                }}
                                placeholder="Enter Otp"
                            />
                        </div>
                        {/* <div className="">
                        <input
                            className="placeholder-black appearance-none border-b-2 border-gray-500 w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            type="password"
                            required
                            value={password}
                            onChange={(e) => {
                              setPassword(e.target.value);
                        }}
                            placeholder="Password"
                        />
                    </div> */}
                        {/* <div className='grid justify-items-end'>
                       
                            <Link>
                                Forgot password?
                            </Link>
                       
                    </div> */}
                        <div className='grid justify-items-center mt-5'>
                            <button className='bg-gradient-to-r to-cyan-500 from-blue-500 hover:bg-blue-700 text-white text-2xl font-bold py-3 mb-2 px-28 rounded-full '>
                                Otp Verify
                            </button>
                            {/* <div className='font-bold  space-y-1 text-sm text-gray-500'>
                            <p >Click here! Forgot Password</p>
                            <div className='flex'>
                                <p >Don't have an account?</p>
                               
                                    <Link to="/signup" className='text-blue-500'>
                                        Sign Up
                                    </Link>
                                 
                            </div>
                        </div> */}
                        </div>
                    </div>
                </div>
            </form>
            <div className="text-sm text-white  pb-2 pt-2 w-full bg-sky-400 mt-52 absolute ">
                <div className="flex ml-60">
                    <img src={appStore} />
                    <img src={playstore} className="ml-2" />
                    <div align="left" className="flex space-x-16 my-auto w-64 pl-5 mr-28 ml-auto">
                        <Link>Home</Link>
                        <Link>Blog</Link>
                        <Link>About</Link>

                    </div>
                </div>
                <div align="left" className="flex space-x-16 bg-white my-auto w-64 pl-5 mr-28 ml-auto">
                </div>
                <p className="ml-60 text-start">&#169;2023- Serendipty| All right reserved</p>
            </div>
        </div>

    )
} 