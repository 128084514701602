import React, { useState, useEffect } from "react"
import HOC from "../component/HOC"
import BaseUrl from "../BaseUrl";
import axios from 'axios'
import swal from "sweetalert";
import { useLocation } from "react-router-dom"

const EditUser = () => {
    const location = useLocation();
    const [profile, setProfile] = useState([]);
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [mobile, setMobile] = useState('');
    const [image, setImage] = useState({ preview: "", raw: "" });
    console.log("image", image.raw)


    const handleChange = e => {
        setImage({
            preview: URL.createObjectURL(e.target.files[0]),
            raw: e.target.files[0]
        });
    };

    const updateProfile = async (event) => {
        const token = await localStorage.getItem("authorization")
        var auth = {
            headers: {
                authorization: `bearer ${token}`,
            },
        };
        event.preventDefault()
        const url = BaseUrl() + "api/user/update-user-profile";
        const fd = new FormData();
        fd.append("firstName", firstName);
        fd.append("lastName", lastName);
        fd.append("mobile", mobile);
        fd.append("profile_pic", image.raw);
        try {
            const res = await axios.post(url, fd, auth);
            console.log("res>>>>>>>..", res)
            swal("Profile Update seccefully", {
                icon: "success"
            });
            if (res.data.ResponseCode == 200) {
                // swal(res.data.ResponseMessage, {
                //   icon: "success",
                // });

            } else {
                // swal(res.data.ResponseMessage)
            }
        } catch (err) {
            console.log("err", err);
            // swal(err.message);
        }
    };



    useEffect(() => {
        var auth = localStorage.getItem("authorization")
        console.log(location.state)
        setProfile(location.state);
        setFirstName(location.state.firstname)
        setLastName(location.state.lastname)
        setEmail(location.state.email)
        setMobile(location.state.mobile)
    }, []);

    return (
        <>
            <div className="w-full max-w-xl mx-auto -mt-10">
                <form onSubmit={updateProfile}>
                    <div className="bg-white shadow-md rounded px-12 py-8">
                    <h1 className='text-400 mb-5 border-b-2 text-2xl'>Edit User</h1>
                        <div className="justify-center mx-auto bg-gray-300 border4 rounded-full h-28 w-28">
                            <label htmlFor="upload-button">
                                {image.preview ? (
                                    <img src={image.preview} alt="dummy" className="h-28 w-28 rounded-full border-2" />
                                ) : (
                                    <>
                                        <img src={BaseUrl() + profile.profile} role="button" className="h-28 w-28 rounded-full border-2" />
                                    </>
                                )}
                            </label>

                        </div>
                        <input
                            type="file"
                            id="upload-button"
                            className="hidden"
                            onChange={handleChange}
                            accept="image/*"
                        />
                        <div className="mb-4">
                            <p className='text-gray-500 mt-5'>{firstName + "  " + lastName}</p>

                            <input
                                className="capitalize placeholder-black appearance-none border-b-2 border-gray-500 w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                type="text"
                                placeholder="First Name"
                                required
                                value={firstName}
                                onChange={(e) => {
                                    setFirstName(e.target.value)
                                }}
                            />
                        </div>

                        <div className="mb-4">
                            <input
                                className="capitalize placeholder-black appearance-none border-b-2 border-gray-500 w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                type="text"
                                required
                                placeholder="Last Name"
                                value={lastName}
                                onChange={(e) => {
                                    setLastName(e.target.value)
                                }}
                            />
                        </div>
                        <div className="mb-4">

                            <input
                                className="capitalize placeholder-black appearance-none border-b-2 border-gray-500 w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                type="text"
                                required
                                placeholder="Mobile"
                                value={mobile}
                                onChange={(e) => {
                                    setMobile(e.target.value)
                                }}
                            />
                        </div>

                        <div className="mb-6">
                            <input
                                disabled
                                className="lowercase cursor-not-allowed  placeholder-black appearance-none border-b-2 border-gray-500 w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                type="text"
                                required
                                placeholder="Email Id"
                                value={email}
                                onChange={(e) => {
                                    setEmail(e.target.value)
                                }}
                            />
                        </div>
                        <div className='grid justify-items-start'>
                        </div>
                        <div className='grid justify-items-center mt-10'>
                            <button className='bg-gradient-to-r to-cyan-500 from-blue-500 hover:bg-blue-700 text-white text-xl font-bold py-1 mb-2 px-16 rounded-full customSwalBtn'>
                                Update
                            </button>

                        </div>
                    </div>
                </form>
            </div>

        </>
    )
}

export default HOC(EditUser)
