import React, { useState, useEffect, useRef } from 'react'
import { useNavigate, useLocation } from "react-router-dom"
import HOC from "./HOC"
import { BiDotsVerticalRounded } from "react-icons/bi";
import BaseUrl from "../BaseUrl";
import axios from 'axios'
import { Menu } from '@headlessui/react'
import swal from "sweetalert";

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}
const UserList = () => {
    const navigate = useNavigate()
    const [data, setData] = useState([]);
    
    const location = useLocation()

    console.log("locationhome>>", location)



    const ViewGoal = async (row) => {
        try {
            navigate('/goal-list-company', {
                state: row
            })
        } catch (error) {
            console.log(error);
        }
    }

    const editUser = async (row) => {
        try {
            navigate('/edit-user', {
                state: row
            })
        } catch (error) {
            console.log(error);
        }
    }




    const deleteUser = async (row) => {
        const token = localStorage.getItem("authorization")
        var auth = {
            headers: {
                authorization: `bearer${token}`,
            }
        };
        swal({
            title: "Are you sure?",
            text: "You Want to Delete ?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then(async (willDelete) => {
            if (willDelete) {
                const url = BaseUrl() + 'api/user/user-delete/' + row.id
                const res = await axios.post(url, auth);
                console.log("ress", res)
                if (res.status == 200) {
                    getMyUser()
                    swal("Delete sesccesfully", {
                        icon: "success"
                    });
                } else {
                    swal(res.data.ResponseMessage, {
                        button: false
                    });
                }
            }
        })
    }

    const getMyUser = async () => {
        const token = localStorage.getItem("authorization")
        var auth = {
            headers: {
                authorization: `bearer ${token}`,
            }
        };
        const url = `${BaseUrl()}api/user/user_list`;
        try {
            const res = await axios.get(url, auth);
            if (res.data.ResponseCode == 200) {
                setData(res.data.userList);
            }
        } catch (error) {
            console.log(error);
        }
    };


    useEffect(() => {
        getMyUser();
    }, []);

    


    return (
           <>
            <div className=" flex justify-center mb-4  ">
                <div className=" md:w-2/3 w-11/12 shadow-lg bg-white  ">
                    <div className="h-16  flex justify-center">
                        <div className=" grid grid-cols-3 px-5 flex justify-center content-center">
                            <div className="content-center flex"> User List</div>
                            <div className=" flex justify-center "></div>
                            <div className="flex justify-end border-slate-500 outline-0 text-current">
                                <>
                                    <div className="relative ">
                                        <div className="absolute inset-y-0 left-0 flex items-center pl-1 pointer-events-none">
                                            <svg
                                                aria-hidden="true"
                                                className="w-5 h-5 text-gray-500 dark:text-gray-400"
                                                fill="none"
                                                stroke="currentColor"
                                                viewBox="0 0 24 24"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    strokeWidth={2}
                                                    d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                                                />
                                            </svg>
                                        </div>
                                        <input
                                            type="Search"
                                            placeholder="Search by name or email"
                                            className="outline-0 border-solid border border-sky-400 block w-full p-2 pl-10
       text-sm text-gray-900 rounded-lg bg-gray-50 
        dark:bg-gray-700
       dark:border-gray-600 dark:placeholder-gray-400 dark:text-white 
       dark:focus:ring-blue-500 dark:focus:border-blue-500 md:w-48 w-40"
                                            required
                                        />
                                    </div>
                                </>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {data.map((item) => {
                console.log("iteem",item)
                return (
                    <div key={item.id} className="flex justify-center">
                        <div className="border-b-2 border-slate-500  md:w-2/3 w-11/12 text-center  shadow-lg bg-white hover:bg-sky-400" role="button">
                            <div className="grid grid-cols-2 gap-60  p-3 ">
                                <div onClick={() => {
                                    ViewGoal(item)
                                }}>{item.fullname}</div>
                                <div>

                                    <Menu as="div" className="relative inline-block md:text-left">
                                        <div className='ml-auto'>
                                            <Menu.Button >
                                                <BiDotsVerticalRounded className="text-current text-3xl" />
                                            </Menu.Button>
                                        </div>

                                        <Menu.Items className="absolute right-0 z-10 -mt-12 w-48  origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                            <div className="py-1">
                                                <Menu.Item>
                                                    {({ active }) => (
                                                        <a
                                                            onClick={() => {
                                                                ViewGoal(item)

                                                            }}
                                                            className={classNames(
                                                                active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                                'block px-4 py-2 text-sm'
                                                            )}
                                                        >
                                                            View
                                                        </a>
                                                    )}
                                                </Menu.Item>
                                                <Menu.Item>
                                                    {({ active }) => (
                                                        <a
                                                            onClick={() => {
                                                                editUser(item)
                                                            }}
                                                            className={classNames(
                                                                active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                                'block px-4 py-2 text-sm'
                                                            )}
                                                        >
                                                            Edit
                                                        </a>
                                                    )}
                                                </Menu.Item>
                                                <Menu.Item>
                                                    {({ active }) => (
                                                        <a

                                                            onClick={() => {
                                                                deleteUser(item)
                                                            }}
                                                            className={classNames(
                                                                active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                                'block px-4 py-2 text-sm'
                                                            )}
                                                        >
                                                            Delete
                                                        </a>
                                                    )}
                                                </Menu.Item>

                                            </div>
                                        </Menu.Items>
                                    </Menu>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            })}
        </>
    )
}

export default HOC(UserList);