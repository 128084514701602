import React from 'react'
import { Link, NavLink, useNavigate } from 'react-router-dom'
import img from '../Assets/Top_design.png'
import Logo from '../Assets/Finel-logo-PNG.png'
import { AiOutlineMenu } from "react-icons/ai";
import BaseUrl from "../BaseUrl";
import "./side.css"
import './header.css'

export default function Header(props) {


    return (
        <>
       <div className="w-full bg-cyan-400 fixed top-0 left-0 z-20 mb-32">
  <div className="flex items-center ">
    <div className="md:w-7/12 lg:ml-52 md:ml-24 ml-10 md:-mt-12 -mt-8">
      <img className="md:h-32 w-44 z-10 h-8 lg:mt-12 md:mt-8 mt-2 py-5" src={Logo} alt="Logo" />
    </div>
    <div className="md:space-x-5 md:ml-96 space-x-3 text-white mt-2 md:text-lg text-sm font-bold md:w-full">
      <Link className="hover:text-gray-700" to="/" onClick={props.scrollToHome}>HOME</Link>
      <Link className="lg:mr-10 hover:text-gray-700" to="/" onClick={props.scrollToAboutUs}>ABOUT US</Link>
      <Link className="lg:mr-10 hover:text-gray-700" to="/" onClick={props.scrollToFeedback}>FEEDBACK</Link>
      {/* <Link className="lg:mr-10 hover:text-gray-700" to="our-news">OUR NEWS</Link> */}
      {/* <Link className="lg:mr-10 hover:text-gray-700" to="contacts">CONTACTS</Link> */}
      <Link className="lg:mr-10 hover:text-gray-700" to="/login">LOG IN</Link>
      <Link className="lg:mr-10 hover:text-gray-700" to="/signup">SIGN UP</Link>
    </div>
  </div>
</div>
            
        </>
    )
}