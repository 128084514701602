import React, { useState } from 'react'
import Header from "./Header"
import BaseUrl from '../BaseUrl'
import axios from 'axios'
import { Link, useNavigate } from 'react-router-dom'
import swal from "sweetalert";
import playstore from '../Assets/PlayStore Button.png'
import appStore from '../Assets/AppStore Button.png'
import Footer from '../component/Footer'



export default function ForgotPassword() {
    const [email, setEmail] = useState("");

    const [errors, setErrors] = useState({
        email: '',
        password: '',
      });
    
      const handleValidation = () => {
        let isValid = true;
        const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    
        if (!emailRegex.test(email)) {
          setErrors((prevErrors) => ({ ...prevErrors, email: 'Invalid email address' }));
          isValid = false;
        } else {
          setErrors((prevErrors) => ({ ...prevErrors, email: '' })); 
        }
    
     
    
        return isValid;
      };


    const navigate = useNavigate()

    const sendOtp = async (e) => {
        e.preventDefault();
        if (handleValidation()) {
        const url = BaseUrl() + "api/user/send-otp";
        const data = {
            email: email,

        };
        try {
            const response = await axios.post(url, data);
            console.log(response)
            if (response.status === 200) {
                localStorage.setItem("authorization", response.data.temptoken);
                navigate("/otp-verify", {
                    state: {
                        data: response.data
                    }
                });
            }
            else {
                swal(response.data.message);
            }
        } catch (error) {
            swal("Email Not found");
        }
    };
};
    return (
        <div>
            <Header />
            <form onSubmit={sendOtp}>
                <div className="w-full max-w-2xl mx-auto md:mt-[408px] ">
                    <div className="bg-white shadow-md z-10 mx-4 absolute rounded px-12 py-16 sm:-mt-96  md:-mt-64 mt-20 w-11/12 md:w-4/5 lg:w-2/5">
                        <div className="mb-4">
                            <input
                                type="email"
                                value={email}
                                onChange={(e) => {
                                    setEmail(e.target.value);
                                }}
                                placeholder="Email Id"
                                className={`appearance-none border-b-2 w-full py-2 px-3 border-gray-500 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${
                                    errors.email && 'border-red-500'
                                  }`}
                                />
                                <div className="mr-auto text-left px-2">
                                  {errors.email && <span className="text-red-500 ">{errors.email}</span>}
                                </div>
                              </div>
                        {/* <div className="">
                        <input
                            className="placeholder-black appearance-none border-b-2 border-gray-500 w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            type="password"
                            required
                            value={password}
                            onChange={(e) => {
                              setPassword(e.target.value);
                        }}
                            placeholder="Password"
                        />
                    </div> */}
                        {/* <div className='grid justify-items-end'>
                       
                            <Link>
                                Forgot password?
                            </Link>
                       
                    </div> */}
                        <div className='grid justify-items-center mt-5'>
                            <button className='bg-gradient-to-r to-cyan-500 from-blue-500 hover:bg-blue-700 text-white text-2xl font-bold py-3 mb-2 px-16 md:px-28 rounded-full '>
                                Send Otp
                            </button>
                            {/* <div className='font-bold  space-y-1 text-sm text-gray-500'>
                            <p >Click here! Forgot Password</p>
                            <div className='flex'>
                                <p >Don't have an account?</p>
                               
                                    <Link to="/signup" className='text-blue-500'>
                                        Sign Up
                                    </Link>
                                 
                            </div>
                        </div> */}
                        </div>
                    </div>
                </div>
            </form>
            <Footer/>
           
        </div>

    )
} 