import React, { useState } from 'react'
import Logo from '../Assets/Finel-logo-PNG.png'
import { useNavigate, NavLink, useLocation } from 'react-router-dom'
import { } from "react-router-dom"



/**
* @author
* @function Home
**/

const SideBar = (props) => {

    const location = useLocation()

    console.log("locationsideBar>>", location)

    const [isActive, setIsActive] = useState(false);
    const handleClick = () => {
        // 👇️ toggle
        setIsActive(current => !current);

        // 👇️ or set to true
        setIsActive(true);
    };
    const navigate = useNavigate()

    const userLogout = async () => {
        localStorage.clear();
        navigate("/login");
    }
    return (
        <>
            {/*<Navbar/>*/}
            <div className="fixed z-40">
                <div className="flex flex-col-reverse ">
                    <div className="flex flex-col h-screen p-3 bg-white  w-64 z-40 ">
                        <div className="space-y-3 z-10 ">
                            <div className="flex items-center">
                                <img src={Logo} className="text-xl h-16 ml-auto mr-auto" />
                            </div>
                            <div className="grid grid-cols-1 ">
                                <div className="flex-1">
                                    <ul className="pt-2 pb-4 space-y-1 text-sm divide-y divide-blue-200  ">
                                        <li className="hover:bg-sky-300 rounded-md">
                                            <NavLink
                                                activeclassname="active"
                                                to="/home"
                                                className="flex items-center p-2 space-x-3 group-focus:bg-yellow-600   rounded-md"
                                            >
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    className="w-6 h-6  group-focus:text-yellow-300"
                                                    fill="none"
                                                    viewBox="0 0 24 24"
                                                    stroke="currentColor"
                                                    strokeWidth={2}
                                                >
                                                    <path
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"
                                                    />
                                                </svg>
                                                <span className="grid grid-cols-3 divide-x">Dashboard</span>
                                            </NavLink>
                                        </li>

                                        <li className=" divide-x hover:bg-sky-400 rounded-md ">
                                            <NavLink
                                                activeclassname="active"
                                                to="/add-goal"
                                                className="flex items-center p-2 space-x-3  rounded-md "

                                            >
                                                <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
                                                className="w-6 h-6" viewBox="0 0 512.000000 512.000000"
                                                preserveAspectRatio="xMidYMid meet">
                                               
                                               <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
                                               fill="#000000" stroke="#000000" stroke-width="150">
                                               <path d="M1558 4608 c-381 -29 -709 -263 -907 -648 -90 -175 -142 -353 -157
                                               -537 l-7 -83 -209 0 c-164 0 -215 -3 -237 -15 -46 -23 -54 -66 -20 -109 l20
                                               -26 224 0 224 0 6 -47 c48 -368 176 -623 460 -911 128 -130 154 -152 180 -152
                                               49 0 75 25 75 70 0 38 -8 47 -143 183 -262 262 -375 481 -421 815 l-6 42 174
                                               0 174 0 117 -140 c115 -138 141 -159 183 -146 11 4 57 54 102 113 97 123 77
                                               130 181 -59 69 -126 88 -148 128 -148 50 0 64 32 217 474 81 236 149 425 151
                                               420 1 -5 70 -270 153 -589 83 -319 155 -595 161 -613 12 -35 35 -52 72 -52 52
                                               0 62 22 147 320 45 157 90 315 101 353 l20 67 344 0 c332 0 346 1 365 20 30
                                               30 27 83 -7 110 -26 20 -33 21 -411 18 -379 -3 -384 -3 -401 -25 -14 -17 -66
                                               -185 -143 -461 -5 -19 -11 -32 -14 -30 -2 3 -69 256 -149 564 -80 307 -150
                                               576 -156 596 -17 66 -101 87 -133 34 -8 -11 -85 -227 -172 -478 -87 -252 -161
                                               -458 -164 -458 -4 0 -36 53 -71 118 -102 183 -126 184 -259 12 -46 -61 -87
                                               -110 -90 -109 -3 0 -45 50 -95 110 -49 61 -100 115 -113 120 -13 5 -111 9
                                               -218 9 l-194 0 0 38 c0 70 31 225 66 332 107 323 339 586 609 691 118 46 205
                                               61 350 61 316 0 629 -129 899 -369 128 -114 155 -133 190 -133 41 0 73 21 181
                                               120 190 173 418 298 640 351 249 59 469 42 673 -52 109 -50 191 -109 278 -201
                                               202 -213 315 -466 345 -772 18 -192 -16 -420 -90 -597 -48 -113 -36 -107 -140
                                               -76 -183 55 -339 144 -484 276 -74 68 -80 71 -126 71 -47 0 -53 -3 -121 -65
                                               -197 -181 -390 -274 -676 -325 -227 -41 -227 -41 -255 -69 -39 -39 -41 -73
                                               -37 -566 l3 -430 37 -106 c43 -125 107 -256 172 -353 l47 -70 -173 -174 c-166
                                               -167 -253 -242 -277 -242 -7 0 -305 290 -663 645 -598 592 -655 645 -685 645
                                               -42 0 -73 -30 -73 -72 -1 -30 50 -83 636 -665 351 -348 655 -645 675 -659 133
                                               -92 183 -67 483 235 l170 170 75 -72 c144 -137 305 -247 495 -338 148 -71 176
                                               -73 294 -19 189 85 368 200 487 314 85 81 91 101 45 148 -40 40 -64 33 -144
                                               -38 -103 -92 -235 -181 -370 -249 -71 -36 -135 -65 -142 -65 -22 0 -173 73
                                               -259 125 -286 173 -493 403 -610 676 -75 175 -76 176 -73 639 l3 413 130 22
                                               c323 55 567 164 752 338 l52 49 53 -47 c188 -170 440 -285 733 -335 72 -12
                                               136 -24 143 -26 9 -3 12 -97 12 -428 l0 -424 -30 -92 c-16 -50 -59 -149 -96
                                               -219 -57 -110 -64 -130 -55 -153 22 -59 86 -64 131 -11 40 49 131 235 167 343
                                               l28 85 3 473 c2 418 1 476 -13 506 -20 42 -60 62 -140 71 -33 4 -63 9 -67 12
                                               -4 2 -1 21 8 41 200 464 143 1008 -146 1414 -73 103 -223 251 -311 308 -181
                                               117 -372 171 -604 171 -371 -1 -707 -143 -1035 -440 l-55 -50 -50 48 c-217
                                               207 -496 359 -766 416 -97 21 -272 32 -381 23z"/>
                                               <path d="M3897 2446 c-26 -7 -61 -26 -77 -41 -51 -49 -60 -81 -60 -214 l0
                                               -121 -117 0 c-161 -1 -218 -28 -258 -122 -17 -43 -21 -241 -5 -298 11 -40 71
                                               -104 113 -119 16 -6 82 -11 147 -11 l118 0 4 -133 c3 -123 5 -136 28 -171 15
                                               -22 45 -47 77 -62 50 -25 59 -26 182 -23 125 4 131 5 172 34 68 48 83 89 87
                                               232 l4 121 127 4 c120 3 130 5 169 31 23 15 51 45 63 68 18 37 20 55 17 183
                                               -3 133 -5 145 -28 179 -14 22 -46 48 -74 62 -44 22 -63 25 -163 25 l-113 0 0
                                               103 c0 113 -8 154 -42 203 -41 59 -86 76 -212 80 -73 3 -127 -1 -159 -10z
                                               m241 -152 c21 -15 22 -21 22 -171 0 -213 -9 -203 200 -203 108 0 160 -4 168
                                               -12 16 -16 16 -210 0 -226 -8 -8 -59 -12 -164 -12 -138 0 -154 -2 -178 -21
                                               l-26 -20 0 -159 c0 -131 -3 -161 -16 -174 -20 -20 -203 -23 -222 -4 -8 8 -12
                                               58 -12 160 0 158 -7 189 -47 207 -14 6 -85 11 -171 11 -102 0 -152 4 -160 12
                                               -16 16 -16 210 0 226 8 8 60 12 169 12 215 0 203 -11 207 202 3 137 6 165 19
                                               176 25 18 183 16 211 -4z"/>
                                               </g>
                                                </svg>
                                                <span className="grid grid-cols-2 divide-x">Add Goal</span>
                                            </NavLink>
                                        </li>

                                        <li className=" divide-x hover:bg-sky-400 rounded-md ">
                                            <NavLink
                                                activeclassname="active"
                                                to="/goal-list"
                                                className="flex items-center p-2 space-x-3  rounded-md "

                                            >
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    className="w-6 h-6"
                                                    fill="none"
                                                    viewBox="0 0 24 24"
                                                    stroke="currentColor"
                                                    strokeWidth={2}
                                                >
                                                    <path
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        d="M20 13V6a2 2 0 00-2-2H6a2 2 0 00-2 2v7m16 0v5a2 2 0 01-2 2H6a2 2 0 01-2-2v-5m16 0h-2.586a1 1 0 00-.707.293l-2.414 2.414a1 1 0 01-.707.293h-3.172a1 1 0 01-.707-.293l-2.414-2.414A1 1 0 006.586 13H4"
                                                    />
                                                </svg>
                                                <span className="grid grid-cols-3 divide-x">Goal List</span>
                                            </NavLink>
                                        </li>
                                        { JSON.parse(localStorage.getItem('profile')).role == "company" &&
                                        <>
                                            <li className="hover:bg-sky-300 rounded-md">
                                            <NavLink
                                                activeclassname="active"
                                                to="/add-user"
                                                className="flex items-center p-2 space-x-3 group-focus:bg-yellow-600 rounded-md"
                                            >
                                                <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
                                                className="w-6 h-6  group-focus:text-yellow-300" viewBox="0 0 512.000000 512.000000"
                                                preserveAspectRatio="xMidYMid meet">
                                                <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
                                                fill="#000000" stroke="none">
                                                <path d="M2370 5105 c-407 -57 -759 -292 -979 -654 -154 -254 -220 -581 -175
                                                -878 50 -342 219 -644 479 -860 45 -37 48 -42 30 -49 -178 -62 -397 -167 -548
                                                -262 -149 -93 -273 -191 -415 -328 -353 -338 -598 -757 -724 -1238 -26 -100
                                                -32 -140 -32 -231 0 -95 4 -121 28 -190 65 -187 203 -324 391 -387 l70 -23
                                                1321 0 1320 0 41 27 c62 41 88 90 88 168 0 78 -26 127 -88 168 l-41 27 -1295
                                                5 c-1195 5 -1298 6 -1327 22 -65 35 -114 115 -114 186 0 35 47 214 86 327 70
                                                202 214 461 353 635 133 166 330 345 511 464 356 234 720 349 1150 363 333 11
                                                628 -47 974 -192 71 -30 98 -36 132 -32 127 14 212 140 173 258 -28 83 -86
                                                122 -294 199 -49 19 -94 37 -98 40 -4 4 18 28 50 54 32 27 91 84 131 129 484
                                                535 463 1356 -48 1867 -303 303 -726 444 -1150 385z m373 -406 c193 -39 354
                                                -125 493 -263 139 -140 224 -299 264 -496 74 -359 -88 -759 -392 -968 -281
                                                -193 -648 -223 -954 -78 -112 53 -165 89 -249 168 -239 223 -350 565 -285 878
                                                40 197 125 356 264 496 230 229 548 327 859 263z"/>
                                                <path d="M4102 1889 c-45 -13 -108 -80 -121 -126 -7 -23 -11 -154 -11 -325 l0
                                                -287 -308 -3 -309 -3 -40 -27 c-62 -41 -88 -90 -88 -168 0 -78 26 -127 88
                                                -168 l40 -27 308 -3 308 -3 3 -307 c3 -303 3 -307 27 -345 13 -21 42 -50 64
                                                -65 34 -23 52 -27 107 -27 55 0 73 4 107 27 22 15 51 44 64 65 24 38 24 42 27
                                                345 l3 307 307 3 c303 3 307 3 345 27 21 13 50 42 65 64 23 34 27 52 27 107 0
                                                55 -4 73 -27 107 -15 22 -44 51 -65 64 -38 24 -42 24 -345 27 l-307 3 -3 307
                                                c-3 303 -3 307 -27 345 -47 76 -151 113 -239 86z"/>
                                                </g>
                                                </svg>
                                                <span className="grid grid-cols-3 divide-x">Add User</span>
                                            </NavLink>
                                            </li>
                                            <li className="hover:bg-sky-300 rounded-md">
                                            <NavLink
                                                activeclassname="active"
                                                to="/my-plan"
                                                className="flex items-center p-2 space-x-3 group-focus:bg-yellow-600 rounded-md"
                                            >
                                                <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
                                                className="w-6 h-6  group-focus:text-yellow-300" viewBox="0 0 512.000000 512.000000"
                                                preserveAspectRatio="xMidYMid meet">

                                                <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
                                                fill="#000000" stroke="none">
                                                <path d="M2370 5105 c-407 -57 -759 -292 -979 -654 -154 -254 -220 -581 -175
                                                -878 50 -342 219 -644 479 -860 45 -37 48 -42 30 -49 -178 -62 -397 -167 -548
                                                -262 -149 -93 -273 -191 -415 -328 -353 -338 -598 -757 -724 -1238 -26 -100
                                                -32 -140 -32 -231 0 -95 4 -121 28 -190 65 -187 203 -324 391 -387 l70 -23
                                                1321 0 1320 0 41 27 c62 41 88 90 88 168 0 78 -26 127 -88 168 l-41 27 -1295
                                                5 c-1195 5 -1298 6 -1327 22 -65 35 -114 115 -114 186 0 35 47 214 86 327 70
                                                202 214 461 353 635 133 166 330 345 511 464 356 234 720 349 1150 363 333 11
                                                628 -47 974 -192 71 -30 98 -36 132 -32 127 14 212 140 173 258 -28 83 -86
                                                122 -294 199 -49 19 -94 37 -98 40 -4 4 18 28 50 54 32 27 91 84 131 129 484
                                                535 463 1356 -48 1867 -303 303 -726 444 -1150 385z m373 -406 c193 -39 354
                                                -125 493 -263 139 -140 224 -299 264 -496 74 -359 -88 -759 -392 -968 -281
                                                -193 -648 -223 -954 -78 -112 53 -165 89 -249 168 -239 223 -350 565 -285 878
                                                40 197 125 356 264 496 230 229 548 327 859 263z"/>
                                                <path d="M4102 1889 c-45 -13 -108 -80 -121 -126 -7 -23 -11 -154 -11 -325 l0
                                                -287 -308 -3 -309 -3 -40 -27 c-62 -41 -88 -90 -88 -168 0 -78 26 -127 88
                                                -168 l40 -27 308 -3 308 -3 3 -307 c3 -303 3 -307 27 -345 13 -21 42 -50 64
                                                -65 34 -23 52 -27 107 -27 55 0 73 4 107 27 22 15 51 44 64 65 24 38 24 42 27
                                                345 l3 307 307 3 c303 3 307 3 345 27 21 13 50 42 65 64 23 34 27 52 27 107 0
                                                55 -4 73 -27 107 -15 22 -44 51 -65 64 -38 24 -42 24 -345 27 l-307 3 -3 307
                                                c-3 303 -3 307 -27 345 -47 76 -151 113 -239 86z"/>
                                                </g>
                                                </svg>

                                                <span className="grid grid-cols-3 divide-x">My Plan</span>
                                            </NavLink>
                                            </li>
                                            <li className="hover:bg-sky-300 rounded-md">
                                                <NavLink
                                                    activeclassname="active"
                                                    to="/user-list"
                                                    className="flex items-center p-2 space-x-3 group-focus:bg-yellow-600 rounded-md"
                                                >
                                                    <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
                                                    className="w-6 h-6  group-focus:text-yellow-300" viewBox="0 0 512.000000 512.000000"
                                                    preserveAspectRatio="xMidYMid meet">
                                                    <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
                                                    fill="#000000" stroke="none">
                                                    <path d="M2395 5109 c-161 -21 -352 -85 -497 -168 -677 -387 -892 -1261 -470
                                                    -1916 64 -100 170 -223 252 -291 38 -32 67 -61 62 -64 -4 -3 -63 -28 -131 -54
                                                    -316 -124 -591 -301 -839 -541 -319 -307 -535 -650 -673 -1066 -109 -329 -114
                                                    -487 -24 -672 59 -120 173 -230 295 -284 120 -54 104 -54 1258 -51 l1068 3 41
                                                    27 c62 41 88 90 88 168 0 78 -26 127 -88 168 l-41 27 -1070 5 c-974 5 -1074 7
                                                    -1101 22 -67 36 -115 113 -115 182 0 45 50 237 90 346 44 122 159 351 227 452
                                                    227 339 524 600 877 772 300 147 561 210 931 226 116 5 246 16 290 24 415 81
                                                    773 353 963 732 42 84 96 242 118 349 25 122 26 383 1 507 -89 449 -388 818
                                                    -806 999 -214 92 -470 127 -706 98z m406 -418 c179 -46 319 -127 449 -260 129
                                                    -130 212 -278 257 -457 24 -95 24 -333 0 -428 -75 -298 -287 -552 -558 -667
                                                    -550 -234 -1169 80 -1316 667 -24 95 -24 333 0 428 46 182 128 328 261 462
                                                    158 157 305 231 556 278 59 11 277 -3 351 -23z"/>
                                                    <path d="M3362 1989 c-47 -14 -109 -79 -123 -131 -23 -89 12 -182 88 -229 l38
                                                    -24 815 0 815 0 38 24 c21 12 49 39 62 59 21 31 25 48 25 112 0 64 -4 81 -25
                                                    112 -13 20 -41 47 -62 59 l-38 24 -800 2 c-487 1 -813 -2 -833 -8z"/>
                                                    <path d="M3362 1189 c-47 -14 -109 -79 -123 -131 -23 -89 12 -182 88 -229 l38
                                                    -24 815 0 815 0 38 24 c21 12 49 39 62 59 21 31 25 48 25 112 0 64 -4 81 -25
                                                    112 -13 20 -41 47 -62 59 l-38 24 -800 2 c-487 1 -813 -2 -833 -8z"/>
                                                    <path d="M3362 389 c-47 -14 -109 -79 -123 -131 -23 -89 12 -182 88 -229 l38
                                                    -24 815 0 815 0 38 24 c21 12 49 39 62 59 21 31 25 48 25 112 0 64 -4 81 -25
                                                    112 -13 20 -41 47 -62 59 l-38 24 -800 2 c-487 1 -813 -2 -833 -8z"/>
                                                    </g>
                                                    </svg>
                                                    <span className="grid grid-cols-3 divide-x">User List</span>
                                                </NavLink>
                                            </li>
                                        </>
                                        }

                                        {/*   <li className="hover:bg-sky-400 rounded-md">
                                <Link
                                    to="#"
                                    className="flex items-center p-2 space-x-3  rounded-md"
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="w-6 h-6"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor"
                                        strokeWidth={2}
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            d="M16 11V7a4 4 0 00-8 0v4M5 9h14l1 12H4L5 9z"
                                        />
                                    </svg>
                                    <span>Completed Goals</span>
                                </Link>
                            </li>*/}
                                        <li className="hover:bg-sky-400 rounded-md">
                                            <NavLink
                                                to="/my-report"
                                                activeclassname="active"
                                                className="flex items-center p-2 space-x-3 rounded-md"
                                            >
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    className="w-6 h-6"
                                                    fill="none"
                                                    viewBox="0 0 24 24"
                                                    stroke="currentColor"
                                                    strokeWidth={2}
                                                >
                                                    <path
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z"
                                                    />
                                                    <path
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                                                    />
                                                </svg>
                                                <span>My Reports</span>
                                            </NavLink>
                                        </li>
                                        <li className="hover:bg-sky-400 rounded-md">
                                            <NavLink
                                                to="/help-center"
                                                activeclassname="active"
                                                className="flex items-center p-2 focus:bg-sky-400 space-x-3 rounded-md"
                                            >
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    className="w-6 h-6"
                                                    fill="none"
                                                    viewBox="0 0 24 24"
                                                    stroke="currentColor"
                                                    strokeWidth={2}
                                                >
                                                    <path
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z"
                                                    />
                                                    <path
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                                                    />
                                                </svg>
                                                <span>Help Center</span>
                                            </NavLink>
                                        </li>
                                        <li className="hover:bg-sky-400 rounded-md">
                                            <div
                                                onClick={userLogout}
                                                role="button"
                                                className="flex items-center p-2 space-x-3 focus:bg-sky-400 rounded-md"
                                            >
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    className="w-6 h-6"
                                                    fill="none"
                                                    viewBox="0 0 24 24"
                                                    stroke="currentColor"
                                                    strokeWidth={2}
                                                >
                                                    <path
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        d="M11 16l-4-4m0 0l4-4m-4 4h14m-5 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h7a3 3 0 013 3v1"
                                                    />
                                                </svg>
                                                <button
                                                    onClick={userLogout}
                                                >Logout</button>

                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default SideBar