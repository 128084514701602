import React, { useState, useEffect, useRef } from "react"
import * as ReactDOM from "react-dom";
import HOC from "../component/HOC"
import { useLocation } from "react-router-dom"
import BaseUrl from "../BaseUrl";
import axios from "axios";
import swal from "sweetalert";
import Moment from "react-moment";
import moment from 'moment';
import Chart from "chart.js/auto";
import { Line, Scatter } from "react-chartjs-2";
import { faker } from '@faker-js/faker';



// import { ChartComponent, SeriesCollectionDirective, SeriesDirective, Inject, ColumnSeries, Legend, DateTime, Tooltip, DataLabel, LineSeries } from '@syncfusion/ej2-react-charts';

// const labels = ["January", "February", "March", "April", "May", "June"];





const MyGoalView = () => {
	const location = useLocation()
	const [measureDate, setMeasureDate] = useState(moment().format('YYYY-MM-DD'))
	const [endDate, setEndDate] = useState("")
	const [width, setWidth] = useState("100%")
	const [graphWeek, setGraphWeek] = useState(0)
	const [skill, setSkill] = useState([])
	const [graphData, setGraphData] = useState([])
	const [graphDataVal, setGraphDataVal] = useState({
		datasets: [
			{
				label: 'Goal',
				data: [],
				backgroundColor: 'rgba(255, 99, 132, 1)',
			},
		],
	})
	const [goalResult, setGoalResult] = useState('')
	const labelsArr = ["Terminated 0", "Intensive Care 1", "ILL 2", "Danger Zone 3", "Bad 4", "Start 5", "Must be better 6", "Sufficient 7", "Good (*) 8", "Very Good 9", "Excellent 10"]

	const ref = useRef(null);
	const options = {
		// width:5000,
		responsive: false,
		plugins: {
			legend: {
				display: false
			},
			// width:5000,
		},
		// maintainAspectRatio: false,
		scales: {
			x: {
				// autoskipping: false,
				min: 0,
				max: graphWeek,
				ticks: {
					stepSize: 1,
					source: 'ticks',
					autoSkip: false,
					autoSkipPadding: 50
				},
				beginAtZero: true,
				display: true,
				stacked: true,
				offset: false,
			},

			y: {
				// type: 'category',
				min: 0,
				max: 10,
				// labels: labelsArr,
				ticks: {
					stepSize: 1,
					source: 'ticks',
					autoSkip: false,
					major: {
						enabled: true
					},
					callback: (t, i) => labelsArr[t] ? labelsArr[t] : t
					// crossAlign: 'far',
				}
			}
		}
	};
	// console.log("data>>",data)

	const token = localStorage.getItem("authorization")

	var auth = {
		headers: {
			authorization: `bearer ${token}`,
		},
	};


	const end = moment(endDate).format('YYYY-MM-DD');
	const ViewGoal = async (row) => {
		const url = BaseUrl() + `api/goal/get-goal-by-id/${location.state.data}`;
		try {
			const res = await axios.get(url, auth);
			if (res.data.ResponseCode == 200) {
				// console.log(res.data.ResponseById.graphData)
				setSkill(res.data.ResponseById.skill);
				setGraphData(res.data.ResponseById.graphData);
				setEndDate(res.data.ResponseById.goalResult.end_Date);
				setGoalResult(res.data.ResponseById.goalResult);

				var gVal = res.data.ResponseById.graphData;
				var graphWeek = 0;
				var gvalArr = gVal.map((item, index) => {
					graphWeek = item.week > graphWeek ? item.week : graphWeek
					return {
						x: item.week,
						y: item.avgRating//labelsArr[item.avgRating]
					}
				})
				setGraphDataVal({
					datasets: [
						{
							label: 'Goal',
							data: gvalArr,
							backgroundColor: 'rgba(255, 99, 132, 1)',
						},
					],
				})
				var firstJan = new Date(moment(res.data.ResponseById.goalResult.start_Date).format('YYYY'))
				var weeks = moment(res.data.ResponseById.goalResult.end_Date).diff(moment(firstJan), 'week')
				graphWeek = weeks > graphWeek ? weeks : graphWeek
				setGraphWeek(parseInt(graphWeek))
			} else {
				// swal(res.data.ResponseMessage,{
				//   button:false
				// });
			}
		} catch (error) {
			console.log(error);
		}
	}

	const updateEndDate = async (row) => {
		const url = BaseUrl() + `api/goal/update-goal/${row.id}`;
		const data = {
			end_Date: endDate
		}
		try {
			const res = await axios.post(url, data, auth);
			swal("Goal Update seccefully", {
				icon: "success"
			});
			if (res.data.ResponseCode == 200) {
				ViewGoal()
				// swal(res.data.ResponseMessage, {
				//   icon: "success",
				// });

			} else {
				// swal(res.data.ResponseMessage)
			}
		} catch (err) {
			console.log("err", err);
			// swal(err.message);
		}
	};
	const changeValue = (e, index) => {
		// console.log(skill,e.target.value, index)
		if (e.target.value > 10 || e.target.value < 0) { e.target.value = 5 }
		var tempskill = [...skill];
		tempskill[index].rating = e.target.value;
		console.log(tempskill)
		setSkill([...tempskill])
	}

	const addRatings = async () => {
		const url = BaseUrl() + `api/goal-rating/update-goal-rating`;
		const data = {
			updated_at: measureDate,
			goalId: location.state.data,
			skillArray: skill.map(item => {
				return {
					skill_id: item._id,
					rating: item.rating
				}
			})
		}
		try {
			const res = await axios.post(url, data, auth);
			console.log(res, res.data.responseCode)
			if (res.data.responseCode == "200") {
				swal("Rating Update seccefully", {
					icon: "success"
				});
				ViewGoal()
			} else {
			}
		} catch (err) {
			console.log("err", err);
			// swal(err.message);
		}
	}
	useEffect(() => {
		setWidth(ref.current ? ref.current.offsetWidth : "100%");
		console.log(ref.current ? ref.current.offsetWidth : "100%")
		setEndDate(end);
		ViewGoal()
	}, []);

	return (
		<>
			{/* <h1>My Goal View</h1> */}
			<div className="flex-wrap flex justify-center ">
				<div className="mb-4 sm:w-full md:w-11/12 -mr-8 lg:w-2/3 h-full px-16  py-8  shadow-lg bg-white text-center ">
					<h1 className="text mx-auto">GOAL DETAILS</h1>

					<div className="relative flex flex-col min-w-0 break-words w-full mb-6  shadow-lg rounded bg-blueGray-700">
						{/* <div className="rounded-t mb-0 px-4 py-3 bg-transparent">
							<div className="flex flex-wrap items-center">
								<div className="relative w-full max-w-full flex-grow flex-1">
									
								</div>
							</div>
						</div> */}
						<div className="p-4 flex-auto">
							<div className="relative h-96 text-7xl text-center font-extrabold">
								<div ref={ref} style={{ overflow: "auto", position: "absolute", height: "380px", width: "100%" }}>
									{width > 0 && graphWeek > 0 &&
										<Scatter options={options} data={graphDataVal} width={20 * graphWeek > width ? 20 * graphWeek : width} height="350" />
									}
								</div>
							</div>
							{/* <ChartComponent id='charts' primaryXAxis={primaryxAxis} primaryYAxis={primaryyAxis} title='Average Sales Comparison'>
					<Inject services={[ColumnSeries, Legend, Tooltip, DataLabel, LineSeries, DateTime]}/>
					<SeriesCollectionDirective>
						<SeriesDirective dataSource={[{x:5,y:400}]} xName='x' yName='y' name='Sales' type='Line'>
						</SeriesDirective>
					</SeriesCollectionDirective>
					</ChartComponent> */}
						</div>
					</div>
					<div className="grid grid-cols-3  lg:grid-cols-5  text-center  flex-wrap border-b pb-16">
						{skill.map((item) => {
							return (
								<div key={item._id} className="rounded-full object-center text-center text-sm">
									<img className="rounded-full hover:bg-sky-40 h-20  justify-center border p-4 my-4 text-center bg-sky-400 " src={BaseUrl() + item.image}
										onClick={e => (item)}
									/>
									<p className='text-center mr-28'>{item.skill}</p>
								</div>
							)
						})}
					</div>
					<div className="flex mb-5">
						<p>Time Duration</p>
					</div>
					<div className="flex ml-auto my-5 -mt-10  w-60">
						<Moment className="border-b-2 border-black" format="MM/DD/YYYY">{goalResult.start_Date}</Moment>
						<p className="px-3">To</p>
						<input className="border-b-2 border-black bg-transparent focus:outline-none focus:shadow-outline"
							type="date"
							value={end}
							onChange={(e) => {
								setEndDate(e.target.value)
							}}
						/>
					</div>
					<button onClick={() => {
						updateEndDate(goalResult)
					}} className="flex ml-auto bg-sky-400 rounded-full px-12 py-2 text-white mb-5 ">Update</button>

					<div className="flex border-t  pt-5 pb-5 w-100">
						<p>Add Rating</p>
						<div className="flex ml-auto">
							<input className="border-b-2 border-black bg-transparent focus:outline-none focus:shadow-outline"
								type="date"
								value={measureDate}
								onChange={(e) => {
									setMeasureDate(e.target.value)
								}}
							/>
						</div>
					</div>
					<div className="grid grid-cols-2 gap-2">
						{skill.map((item, index) => {
							return (<>
								<div><p className='text-center mr-28'>{item.skill}</p></div>
								<div>
									<input className="capitalize placeholder-black appearance-none border-b-2 border-gray-500 w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
										type="number" placeholder="rating" value={item.rating} max="10" required
										onChange={e => { changeValue(e, index) }}
									/></div>
							</>)
						})}
					</div>
					<button onClick={() => {
						addRatings()
					}} className="flex ml-auto bg-sky-400 rounded-full px-12 py-2 text-white mt-5 ">Add</button>
				</div>
			</div>
		</>
	)
}

export default HOC(MyGoalView)
