import React, { useState, useEffect, useRef } from 'react'
import { AiOutlineMenu } from "react-icons/ai";
import { useNavigate, NavLink } from "react-router-dom";
import BaseUrl from "../BaseUrl";
import axios from 'axios'
import Logo from '../Assets/Finel-logo-PNG.png'
import "./side.css"
import useOnClickOutside from "./use-onclick-outside";

export default function Navbar() {
    const navigate = useNavigate()

    const [profile, setProfile] = useState([]);
    const [sideBar, setSideBar] = useState(false);

    const ref = useRef();

    useOnClickOutside(ref, () => setSideBar(false));

    const getProfile = async () => {
        const token = await localStorage.getItem("authorization")
        var auth = {
            headers: {
                authorization: `bearer ${token}`,
            }
        };
        const url = BaseUrl() + "api/user/get-user-profile";
        try {
            const res = await axios.get(url, auth);
            if (res.data.ResponseCode == 200) {
                setProfile(res.data.Response);
            }
        } catch (error) {
            console.log(error);
        }
    };

    const userLogout = async () => {
        localStorage.clear();
        navigate("/login");
    }


    useEffect(() => {
        getProfile();
    }, []);



    return (
        <>
            <div className="md:mb-44 mb-14">
                <nav className="bg-sky-400 fixed flex md:w-12/8 w-full  z-10 h-12 md:h-20 ">
                    <div className="container md:px-60 md:flex flex md:items-center ">
                        <div className="flex justify-between items-center">
                            <button
                                onClick={() => setSideBar(true)}
                                className=" px-3 py-1 rounded text-gray-600 opacity-50 hover:opacity-75 md:hidden"
                                id="navbar-toggle"
                            >
                                <AiOutlineMenu
                                    className="text-white "
                                />
                            </button>
                        </div>
                        <div
                            className="hidden flex md:flex flex-col md:flex-row  flex-row md:ml-auto ml-auto mt-3 md:mt-0"
                            id="navbar-collapse"
                        >
                            <div className="w-full h-[80px]  text-[#000] flex items-center px-6">
                                <div className="flex space-x-12 items-center">
                                    {/* <div className="w-[30px] h-[30px] rounded-[999px] border-2 overflow-hidden">
                  <img className="w-full h-full object-cover" role='button' src ="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTCZcMhE6YuXrAhW9luHf43IAhYMYbJ15XH8rSsLUHakQ&s"/>  
                </div> */}
                                    <div className="flex flex-col items-center">
                                        <div className="w-[40px] h-[40px] rounded-[999px] border-2 overflow-hidden">
                                            <img className='w-full h-full object-cover' onClick={() => navigate('/my-profile')} role='button' src={`${BaseUrl()}${profile.profile}`} />
                                        </div>
                                        <div className="text-sm bold font-semibold md:visible invisible">{profile.fullName}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </nav>
            </div>

            {/* SideBar start */}

            {sideBar &&
                <div
                    ref={ref}
                    className="fixed -mt-16     z-10">
                    <div className="flex flex-col-reverse ">
                        <div className="flex flex-col h-screen p-3 bg-white  w-64 ">
                            <div className="space-y-3 z-10 ">
                                <div className="flex items-center">
                                    <img src={Logo} className="text-xl h-16 ml-auto mr-auto" />
                                </div>
                                <div className="grid grid-cols-1 ">
                                    <div className="flex-1">
                                        <ul className="pt-2 pb-4 space-y-1 text-sm divide-y divide-blue-200  ">
                                            <li className="hover:bg-sky-300 rounded-md">
                                                <NavLink
                                                    activeclassname="active"
                                                    to="/home"
                                                    className="flex items-center p-2 space-x-3 group-focus:bg-yellow-600   rounded-md"
                                                >
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        className="w-6 h-6  group-focus:text-yellow-300"
                                                        fill="none"
                                                        viewBox="0 0 24 24"
                                                        stroke="currentColor"
                                                        strokeWidth={2}
                                                    >
                                                        <path
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                            d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"
                                                        />
                                                    </svg>
                                                    <span className="grid grid-cols-3 divide-x">Dashboard</span>
                                                </NavLink>
                                            </li>

                                            <li className="hover:bg-sky-300 rounded-md">
                                                <NavLink
                                                    activeclassname="active"
                                                    to="/my-profile"
                                                    className="flex items-center p-2 space-x-3 group-focus:bg-yellow-600   rounded-md"
                                                >
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        className="w-6 h-6  group-focus:text-yellow-300"
                                                        fill="none"
                                                        viewBox="0 0 24 24"
                                                        stroke="currentColor"
                                                        strokeWidth={2}
                                                    >
                                                        <path
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                            d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"
                                                        />
                                                    </svg>
                                                    <span className="grid grid-cols-3 divide-x">Profile</span>
                                                </NavLink>
                                            </li>
                                            <li className=" divide-x hover:bg-sky-400 rounded-md ">
                                                <NavLink
                                                    activeclassname="active"
                                                    to="/add-goal"
                                                    className="flex items-center p-2 space-x-3  rounded-md "

                                                >
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        className="w-6 h-6"
                                                        fill="none"
                                                        viewBox="0 0 24 24"
                                                        stroke="currentColor"
                                                        strokeWidth={2}
                                                    >
                                                        <path
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                            d="M20 13V6a2 2 0 00-2-2H6a2 2 0 00-2 2v7m16 0v5a2 2 0 01-2 2H6a2 2 0 01-2-2v-5m16 0h-2.586a1 1 0 00-.707.293l-2.414 2.414a1 1 0 01-.707.293h-3.172a1 1 0 01-.707-.293l-2.414-2.414A1 1 0 006.586 13H4"
                                                        />
                                                    </svg>
                                                    <span className="grid grid-cols-3 divide-x">Add Goal</span>
                                                </NavLink>
                                            </li>
                                            {/*   <li className="hover:bg-sky-400 rounded-md">
                                <Link
                                    to="#"
                                    className="flex items-center p-2 space-x-3  rounded-md"
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="w-6 h-6"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor"
                                        strokeWidth={2}
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            d="M16 11V7a4 4 0 00-8 0v4M5 9h14l1 12H4L5 9z"
                                        />
                                    </svg>
                                    <span>Completed Goals</span>
                                </Link>
                            </li>*/}
                                            <li className="hover:bg-sky-400 rounded-md">
                                                <NavLink
                                                    to="/my-report"
                                                    activeclassname="active"
                                                    className="flex items-center p-2 space-x-3 rounded-md"
                                                >
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        className="w-6 h-6"
                                                        fill="none"
                                                        viewBox="0 0 24 24"
                                                        stroke="currentColor"
                                                        strokeWidth={2}
                                                    >
                                                        <path
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                            d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z"
                                                        />
                                                        <path
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                            d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                                                        />
                                                    </svg>
                                                    <span>My Reports</span>
                                                </NavLink>
                                            </li>
                                            <li className="hover:bg-sky-400 rounded-md">
                                                <NavLink
                                                    to="/help-center"
                                                    activeclassname="active"
                                                    className="flex items-center p-2 focus:bg-sky-400 space-x-3 rounded-md"
                                                >
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        className="w-6 h-6"
                                                        fill="none"
                                                        viewBox="0 0 24 24"
                                                        stroke="currentColor"
                                                        strokeWidth={2}
                                                    >
                                                        <path
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                            d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z"
                                                        />
                                                        <path
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                            d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                                                        />
                                                    </svg>
                                                    <span>Help Center</span>
                                                </NavLink>
                                            </li>
                                            <li className="hover:bg-sky-400 rounded-md">
                                                <div
                                                    onClick={userLogout}
                                                    role="button"
                                                    className="flex items-center p-2 space-x-3 focus:bg-sky-400 rounded-md"
                                                >
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        className="w-6 h-6"
                                                        fill="none"
                                                        viewBox="0 0 24 24"
                                                        stroke="currentColor"
                                                        strokeWidth={2}
                                                    >
                                                        <path
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                            d="M11 16l-4-4m0 0l4-4m-4 4h14m-5 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h7a3 3 0 013 3v1"
                                                        />
                                                    </svg>
                                                    <button
                                                        onClick={userLogout}
                                                    >Logout</button>

                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}

