import React, { Component, useRef, useEffect } from 'react'
import Header from "./Header"
import { Link } from 'react-router-dom'



export default function TermsAndConditions() {
    return (
        <div>
            <Header />
            <div className="lg:w-1/2 w-auto md:w-11/12 mx-auto mt-32">

                <p className="font_8 wixui-rich-text__text" style={{ "font-size": "14px" }}>Terms & Conditions</p>
                <br />

                <p style={{ "font-size": "14px", textAlign: 'left' }}>By downloading or using the app, these terms will automatically apply to you &ndash; you should make sure therefore that you read them carefully before using the app. You are not allowed to copy or modify the app, any part of the app, or our trademarks in any way. You are not allowed to attempt to extract the source code of the app, and you also should not try to translate the app into other languages or make derivative versions. The app itself, and all the trademarks, copyright, database rights, and other intellectual property rights related to it, are the sole property of serendipity, Inc.</p>
                <br />

                <p style={{ "font-size": "14px", textAlign: 'left' }}>serendipity, Inc. is committed to ensuring that the app is as useful and efficient as possible. For that reason, we reserve the right to make changes to the app or to charge for its services, at any time and for any reason. We will never charge you for the app or its services without making it very clear to you exactly what you’re paying for.</p>
                <br />

                <p style={{ "font-size": "14px", textAlign: 'left' }}>The serendipity app stores and processes personal data that you have provided to us, to provide our Services. It is your responsibility to keep your phone – and other electronic devices where you may have downloaded this app – secure. serendipity makes it a priority to protect the personal information provided to us secure and safe.</p>
                <br />

                <p style={{ "font-size": "14px", textAlign: 'left' }}>We therefore recommend that you do not jailbreak, root or compromise the integrity of your phone, or other electronic device, which is the process of removing software restrictions and limitations imposed by the official operating system of your device. Compromising the integrity of your electronic devices increases its susceptibility to malware, viruses, and malicious programs which may affect the serendipity app and cause it to malfunction.</p>
                <br />

                <p style={{ "font-size": "14px", textAlign: 'left' }}>The app does use third-party services that declare their Terms and Conditions.</p>
                <br />

                <p style={{ "font-size": "14px", textAlign: 'left' }}>Links to the Terms and Conditions of third-party service providers used by the serendipity app:</p>

                <ul style={{ 'list-style': 'circle', 'margin': '0 15px' }}>
                    <li>
                        <p style={{ "font-size": "14px", textAlign: 'left' }}><a href="https://policies.google.com/terms" target="_blank" rel="noreferrer noopener" >Google Play Services</a></p>
                    </li>
                </ul>
                <br />

                <p style={{ "font-size": "14px", textAlign: 'left' }}>By downloading this app you agree to indemnify and hold harmless serendipity, Inc. of any and all malicious, criminal, and immoral activities the user may engage with our app.  This app is designed to be wholesome and family friendly therefore we do not condone any acts to the contrary.</p>
                <br />

                <p style={{ "font-size": "14px", textAlign: 'left' }}>Certain functions of the app will require the app to have an active internet connection. The connection can be Wi-Fi or provided by your mobile network provider, but serendipity, Inc. does not take responsibility for the app not working at full functionality if you do not have access to Wi-Fi, or there is limited internet accessibility, or your electronic device has insufficient data.</p>
                <br />

                <p style={{ "font-size": "14px", textAlign: 'left' }}>If you are using the app outside of an area with Wi-Fi, you should remember that the terms of the agreement with your mobile network provider will still apply. As a result, you may be charged by your mobile provider for the cost of data for the duration of the connection while accessing the app, or other third-party charges. In using the app, you are accepting responsibility for any such charges, including roaming data charges if you use the app outside of your home territory (i.e. region or country) without turning off data roaming. If you are not the bill payer for the device on which you are using the app, please be aware that we assume that you have received permission from the bill payer for using the app. By downloading this app you agree to hold serendipity, Inc. harmless of any and all additional charges assessed by your mobile provider.</p>
                <br />

                <p style={{ "font-size": "14px", textAlign: 'left' }}>serendipity, Inc. is not responsible for any interruption in connection while accessing our Service due to insufficient battery life, or any other functionality of your electronic device.  It is solely the responsibility of the user to make sure the electronic device is in working order prior to accessing our Service.</p>
                <br />

                <p style={{ "font-size": "14px", textAlign: 'left' }}>serendipity relies on third parties to provide information to us regarding updates so that we can make it available to you. serendipity, Inc. accepts no liability for any loss, direct or indirect, you experience as a result of relying wholly on this functionality of the app.</p>
                <br />

                <p style={{ "font-size": "14px", textAlign: 'left' }}>The app is currently available on Android and iOS – the requirements for these systems (or any additional systems) may change, and the user will need to download all applicable updates to ensure that app is working at its optimal level.</p>
                <br />

                <p style={{ "font-size": "14px", textAlign: 'left' }}>As the user, you warrant and agree to accept updates to the application when offered to you to ensure that the app is working at its optimal level. serendipity may at any time terminate this app and no longer provide our Service without giving notice of termination to you. Upon any termination, (a) the rights and licenses granted to you in these terms will end; (b) you must stop using the app, and (if needed) delete it from your device.</p>
                <br />

                <p style={{ "font-size": "14px", textAlign: 'left' }}>Changes to These Terms and Conditions</p>
                <p style={{ "font-size": "14px", textAlign: 'left' }}>We may update our Terms and Conditions from time to time. Thus, you are advised to review this page periodically for any changes. We will notify you of any changes by posting the new Terms and Conditions on this page.</p>
                <br />
                <p style={{ "font-size": "14px", textAlign: 'left' }}>These terms and conditions are effective as of 2023-01-20</p>
                <br />
            </div>

        </div>
    )
};