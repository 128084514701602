import React, { useState, useEffect } from "react"
import HOC from "../component/HOC"
import BaseUrl from '../BaseUrl'
import axios from 'axios'
import swal from "sweetalert";


const HelpCenter = () => {
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");

    const AddHelp = async (e) => {
        e.preventDefault();
        const token = await localStorage.getItem("authorization")
        var auth = {
            headers: {
                authorization: `bearer ${token}`,
            },
        };
        const url = BaseUrl() + "api/help/add/help";
        const data = {
            name: name,
            email: email,
            query: message,
        };
        try {
            const response = await axios.post(url, data, auth);
            if (response.data.ResponseCode === 200) {
                swal(response.data.ResponseMessage, {
                    icon: "success"
                });
            }

        } catch (error) {
            swal(error.message);
        }
    }


    return (
        <>
            <div className="md:w-11/12 md:pl-7 max-w-xl mx-auto">
                <h1 className='text-gray-400 shadow-xl mb-5 text-2xl'>Help Center</h1>
                <div className="bg-white shadow-md rounded px-12 py-16">
                    <div className="mb-4">
                        <input
                            className="capitalize placeholder-black appearance-none border-b-2 border-gray-500 w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            type="text"
                            placeholder="Name"
                            value={name}
                            onChange={(e) => {
                                setName(e.target.value);
                            }}
                        />
                    </div>
                    <div className="mb-6">
                        <input
                            className="lowercase placeholder-black appearance-none border-b-2 border-gray-500 w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            type="text"
                            placeholder="Email Id"
                            value={email}
                            onChange={(e) => {
                                setEmail(e.target.value);
                            }}
                        />
                    </div>
                    <div className='grid justify-items-start'>

                        <label
                            htmlFor="message"
                            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                        >
                            Your message
                        </label>
                        <textarea
                            id="message"
                            className=" focus:outline-none  block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            placeholder="Write your problum here..."
                            value={message}
                            onChange={(e) => {
                                setMessage(e.target.value);
                            }}
                        />
                    </div>
                    <div className='grid justify-items-center mt-10'>
                        <button onClick={AddHelp} className='bg-gradient-to-r to-cyan-500 from-blue-500 hover:bg-blue-700 text-white text-xl font-bold py-1 mb-2 px-16 rounded-full '>
                            Submit
                        </button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default HOC(HelpCenter)
