import React, { useState, useEffect } from "react";
import { useSearchParams, useNavigate } from "react-router-dom"
import Navbar from "./innerHader";
import Sidebar from "./sideBar";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import axios from "axios";
import BaseUrl from "../BaseUrl"
import Ideal from '../Assets/Ideal.png'
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import CheckoutForm from './CheckoutForm';

// Make sure to call `loadStripe` outside of a component's render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe("pk_live_51MtVVsAq8B2KTw3f7ewjMwyy6SbMVasMe3Oa07tj5mdKgZR3jHltCO8UJm0uQrC5NDYV0QRHG4hYUZUUW7vzbI7W00a6luawhF");
var apiCall = true;
const PAYPAL_CLIENT_ID = process.env.PAYPAL_CLIENT_ID
const HOC = (Wcomponenet) => {
    return function Component() {
        const [hamb, setHamb] = useState(false);
        const [amount, setAmount] = useState(15);
        const [showModal, setShowModal] = useState(false);
        const [showInput, setShowInput] = useState(false);
        const [showIdealForm, setShowIdealForm] = useState(false);
        const [showButton, setShowButton] = useState(true);
        const [showPayButton, setShowPayButton] = useState(true);
        const [crossButton, setCrossButton] = useState();
        const [success, setSuccess] = useState(false);
        const [ErrorMessage, setErrorMessage] = useState("");
        const [orderID, setOrderID] = useState(false);
        // const [apiCall, setApiCall] = useState(true);
        const navigate = useNavigate()
        const [searchParams, setSearchParams] = useSearchParams()

        useEffect(() => {

            var popUpClose = localStorage.getItem('popUpClose');

            var profile = JSON.parse(localStorage.getItem('profile'))
            striprPayment();
            // console.log("profile",profile,profile.subcription_popup )
            if (popUpClose != 'true' && profile && profile.subcription_popup == 0) {
                setShowModal(true)
                setCrossButton(false)
            }
            if (popUpClose != 'true' && profile && profile.subcription_popup == 1) {
                setShowModal(true)
                setCrossButton(true)
            }
            if (profile && profile.subcription_popup == 2) {
                setShowModal(false)
                setCrossButton(false)
            }

            if (success) {
                // alert("Payment successful!!");
                console.log('Order successful . Your order id is--', orderID);
            }
        }, [success]);

        const popupClose = () => {
            localStorage.setItem("popUpClose", "true");
            setShowModal(false)
        }

        // creates a paypal order
        const createOrder = (data, actions) => {
            return actions.order.create({
                intent: "CAPTURE",
                purchase_units: [
                    {
                        description: "Serendipity",
                        amount: {
                            currency_code: "EUR",
                            value: amount,
                        },
                    },
                ],
            }).then((orderID) => {
                setOrderID(orderID);
                return orderID;
            });
        };

        const striprPayment = () => {
            var popUpClose = localStorage.getItem('popUpClose');
            var payment_intent = searchParams.get("payment_intent")
            var payment_intent_client_secret = searchParams.get("payment_intent_client_secret")
            var redirect_status = searchParams.get("redirect_status")
            console.log("popUpClose", apiCall, payment_intent, payment_intent_client_secret, redirect_status)
            if (apiCall === true && redirect_status == "succeeded") {
                apiCall = false;
                var token = localStorage.getItem("authorization")
                var auth = {
                    headers: {
                        authorization: `bearer ${token}`,
                    }
                };
                const url = BaseUrl() + "api/user/paypalPayment"
                const data = {
                    "amount": amount,
                    "paymentId": payment_intent,
                    "clientSecret": payment_intent_client_secret,
                    "reason": "",
                    "paymentstatus": "1",
                    "paymentMethod": "stripe",
                    "paymentType": amount == 15 ? "default" : "custom"
                }

                try {
                    axios.post(url, data, auth).then((response) => {
                        console.log(response)
                        if (response.status === 200) {
                            var profile = JSON.parse(localStorage.getItem('profile'))
                            profile.subcription_popup = 2;
                            localStorage.setItem("profile", JSON.stringify(profile));
                            popupClose();
                            navigate("/home");
                        }
                        else {
                            console.log(response.data.message);
                        }
                    });
                } catch (error) {
                }
            }
        }

        // check Approval
        const onApprove = (data, actions) => {
            return actions.order.capture().then(async (details) => {
                // const { payer } = details;
                console.log("details", details.purchase_units[0].payments.captures[0].id)
                setSuccess(true);

                const token = localStorage.getItem("authorization")
                var auth = {
                    headers: {
                        authorization: `bearer ${token}`,
                    }
                };
                const url = BaseUrl() + "api/user/paypalPayment"
                const data = {
                    "amount": amount,
                    "paymentId": details.purchase_units[0].payments.captures[0].id,
                    "reason": "",
                    "paymentstatus": "1",
                    "paymentType": amount == 15 ? "default" : "custom"
                }

                try {
                    const response = await axios.post(url, data, auth);
                    if (response.status === 200) {
                        var profile = JSON.parse(localStorage.getItem('profile'))
                        profile.subcription_popup = 2;
                        localStorage.setItem("profile", JSON.stringify(profile));
                        popupClose();
                    }
                    else {
                        console.log(response.data.message);
                    }
                } catch (error) {
                }

            });
        };

        //capture likely error
        const onError = (data, actions) => {
            setErrorMessage("An Error occured with your payment ");
        };
        return (
            <>

                {showModal ? (
                    <>
                        <div
                            className="justify-center  flex  mb-4   items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
                        >
                            <div className="relative w-auto my-6 mx-auto max-w-3xl">
                                <div className="">
                                    <div className="relative px-36  flex-auto">
                                        <div className="w-full max-w-sm bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
                                            {crossButton ? (
                                                <>
                                                    <button
                                                        className="float-right"
                                                        style={{ width: "30px" }}
                                                        onClick={() => popupClose()}
                                                    >
                                                        <span
                                                            className="text-3xl text-sky-400"
                                                        >
                                                            ×
                                                        </span>
                                                    </button>
                                                </>
                                            ) : null}
                                            <div className="w-full px-5 pb-5 pt-5">
                                                <a href="#">
                                                    <h5 style={{ height: "0px" }} className="text-xl font-semibold tracking-tight text-white">Apple Watch Series 7 GPS, Aluminium Case, Starlight Sport</h5>
                                                    <h5 className="text-xl font-semibold tracking-tight text-gray-900 dark:text-white">Enter the happy life!</h5>
                                                </a>
                                                <div className="flex1 items-center justify-between">
                                                    <p className="text-3xl font-bold text-gray-900 dark:text-white">€{amount}</p>
                                                    <br></br>
                                                    {!showInput && showPayButton && !showIdealForm &&
                                                        <>
                                                            <button onClick={e => setShowPayButton(false)} className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Pay Now</button>
                                                            <div className="inline-flex items-center justify-center w-full">
                                                                <hr className="w-64 h-px my-8 bg-gray-200 border-0 dark:bg-gray-700" />
                                                                <span className="absolute px-3 font-medium text-gray-900 -translate-x-1/2 bg-white left-1/2 dark:text-white dark:bg-gray-900">or</span>
                                                            </div>
                                                            <button onClick={e => {
                                                                setShowInput(true)
                                                            }} className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Pay your choice</button>
                                                        </>
                                                    }
                                                    {showInput ? (
                                                        <><input
                                                            type="text"
                                                            value={amount}
                                                            placeholder="15"
                                                            className="placeholder-black  appearance-none border-b-2 
                            border-gray-500 w-full py-2 px-3 text-gray-700 leading-tight
                            focus:outline-none mb-5 focus:shadow-outline"
                                                            onChange={e => { console.log(e.target.value); setAmount(e.target.value) }}
                                                        />
                                                            <br></br>
                                                            <button onClick={e => { setShowPayButton(false); setShowInput(false) }} className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Pay Now</button>
                                                        </>
                                                    ) : null}
                                                    <PayPalScriptProvider options={{ "currency": "EUR", "client-id": "AazXOEoLXlWy0SG5wHyDJ7Zm69FiSzU-m1cNbY0KbLlCkcirbSV-PjFSbq-4R8z5K0A8QHUPZvxQMx_G" }}>
                                                        {!showPayButton ? (
                                                            <PayPalButtons
                                                                style={{ layout: "vertical" }}
                                                                createOrder={createOrder}
                                                                onApprove={onApprove}
                                                                fundingSource="paypal"
                                                            />
                                                        ) : null}
                                                    </PayPalScriptProvider>
                                                    {!showPayButton ? (
                                                        <>
                                                            <div className="inline-flex items-center justify-center w-full">
                                                                <hr className="w-64 h-px my-8 bg-gray-200 border-0 dark:bg-gray-700" />
                                                                <span className="absolute px-3 font-medium text-gray-900 -translate-x-1/2 bg-white left-1/2 dark:text-white dark:bg-gray-900">or</span>
                                                            </div>
                                                            <button type="button" className=" w-full text-white bg-[#2557D6] hover:bg-[#2557D6]/90 focus:ring-4 focus:ring-[#2557D6]/50 focus:outline-none font-medium text-sm text-center inline-block items-center dark:focus:ring-[#2557D6]/50 mr-2 mb-2" style={{ "font-size": "16px", "height": "45px", "border-radius": "0.2rem" }}
                                                                onClick={e => { setShowIdealForm(true); setShowPayButton(true) }}>
                                                                <img src={Ideal} className="h-full inline-block" />
                                                                Pay with iDEAL
                                                            </button>
                                                        </>
                                                    ) : null}
                                                    {showIdealForm ? (
                                                        <Elements stripe={stripePromise}>
                                                            <CheckoutForm amount={amount} />
                                                        </Elements>
                                                    ) : null}
                                                </div>
                                            </div>
                                        </div>
                                        <br></br>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                    </>
                ) : null}
                <section className="flex overflow-x-hidden">
                    <div
                        className={
                            hamb
                                ? "absolute top-0 z-50 md:w-auto shadow-md w-60 transition-all md:-left-full left duration-150  h-screen  left-0 "
                                : "w-72 z-50 shadow-md  md:static absolute top-0  -left-full  h-screen transition-all duration-150 overflow-y-auto "
                        }
                    >
                        <Sidebar
                            onClick={() => {
                                setHamb(true);
                            }}
                        />
                    </div>
                    {/* Components & Navbar */}
                    <div
                        className={
                            hamb
                                ? " transition-all duration-150 w-full h-screen"
                                : " w-full h-screen  md:pl-3  z-20 transition-all duration-150 "
                        }
                    >
                        <Navbar hamb={hamb} setHamb={setHamb} />
                        <div className="text-current z-20  wcomp w-full h-[590px]">
                            <Wcomponenet />
                        </div>
                    </div>
                </section>
            </>
        );
    };
};

export default HOC;
