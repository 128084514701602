import React, { useEffect, useState } from 'react'
import Header from "./Header"
import BaseUrl from '../BaseUrl'
import axios from 'axios'
import swal from "sweetalert";
import playstore from '../Assets/PlayStore Button.png'
import appStore from '../Assets/AppStore Button.png'
import Footer from './Footer'

import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import CheckoutForm from './CheckoutForm';
import Ideal from '../Assets/Ideal.png'
import { useSearchParams, useNavigate } from "react-router-dom"
const stripePromise = loadStripe("pk_live_51MtVVsAq8B2KTw3f7ewjMwyy6SbMVasMe3Oa07tj5mdKgZR3jHltCO8UJm0uQrC5NDYV0QRHG4hYUZUUW7vzbI7W00a6luawhF");
var apiCall = true;

export default function StripePayment() {
    const navigate = useNavigate()
    const [amount, setAmount] = useState(0);
    const [paymentStatus, setPaymentStatus] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams()

    useEffect(() => {
        var getAmount = searchParams.get("amount")
        var token = searchParams.get("token")
        var redirect_status = searchParams.get("redirect_status") ? searchParams.get("redirect_status") : ""
        if (getAmount && token && redirect_status != "succeeded") {
            localStorage.setItem("authorization", token)
            localStorage.setItem("amount", parseInt(getAmount))
            setAmount(parseInt(getAmount))
        } else {
            striprPayment()
        }
    })

    const striprPayment = () => {
        var payment_intent = searchParams.get("payment_intent")
        var payment_intent_client_secret = searchParams.get("payment_intent_client_secret")
        var redirect_status = searchParams.get("redirect_status")

        if (apiCall === true && redirect_status == "succeeded") {
            apiCall = false;
            var token = localStorage.getItem("authorization")
            var amountVal = localStorage.getItem("amount")
            var auth = {
                headers: {
                    authorization: `bearer ${token}`,
                }
            };
            const url = BaseUrl() + "api/user/paypalPayment"
            const data = {
                "amount": amountVal,
                "paymentId": payment_intent,
                "clientSecret": payment_intent_client_secret,
                "reason": "",
                "paymentstatus": "1",
                "paymentMethod": "stripe",
                "paymentType": amount == 15 ? "default" : "custom"
            }

            try {
                axios.post(url, data, auth).then((response) => {
                    console.log(response)
                    if (response.status === 200) {
                        setPaymentStatus(true);
                    }
                    else {
                        console.log(response.data.message);
                    }
                });
            } catch (error) {
            }
        }
    }

    return (
        <div>
            {amount > 0 && !paymentStatus &&

                <div className=" max-w-2xl mx-auto mt-64">
                    <p><img src={Ideal} width="50" className="w-50 inline-block" />Pay with iDEAL</p>
                    <div className="flex1 items-center justify-between">
                        <p className="text-3xl font-bold text-gray-900 dark:text-white">€{amount}</p>
                    </div>
                    <div className="inline-flex items-center justify-center w-full">
                        <hr className="w-64 h-px my-8 bg-gray-200 border-0 dark:bg-gray-700" />
                    </div>
                    <Elements stripe={stripePromise}>
                        <CheckoutForm amount={amount} />
                    </Elements>
                </div>

            }
            {paymentStatus &&
                <div className=" max-w-2xl mx-auto mt-64">
                    <p>Payment Done</p>
                </div>
            }
            {!amount && !paymentStatus &&
                <div className=" max-w-2xl mx-auto mt-64">
                    <p>404 page not found!</p>
                </div>
            }
        </div>
    )
} 