import React, { useState, useEffect } from "react"
import Header from "../component/Header"

const NotFound = () => {
    return (
        <>
            <Header />
            <div className="max-w-2xl mx-auto md:mt-72 text-sky-500 text-6xl font-extrabold">
                <h1>404 Page Not Found!</h1>
            </div>
        </>
    )
}

export default NotFound
