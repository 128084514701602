import React, { Component, useRef, useEffect } from 'react'
import Header from "../component/Header"
export default function About() {

	return (
		<div>
			<Header />
			<div className="md:w-1/2 max-w-full sm:w-auto mx-auto">
				<h1 className="text-4xl font-bold text-sky-500 md:mt-24 mt-16 ">About Us</h1>
				<p className="text-sky-500 m-5">We are the World Trade Port, the inventors of the Blue Sky Index™
					your connection to the Multiverse.
					The BSI is the steppingstone to a new multiworld where Fair BSI-Based Barter will be the foundation for
					prosperity and peace for all people and countries. The money generated with the BSI will be invested in the Project Generators, which are needed for fast flash barter trading , entertainment, culture, youth, animals and nature.
				</p>
			</div>
		</div>
	)
}