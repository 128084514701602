import React, { useState } from 'react';
import Header from './Header';
import { Link, useNavigate } from 'react-router-dom';
import BaseUrl from '../BaseUrl';
import axios from 'axios';
import swal from 'sweetalert';
import Footer from './Footer';

export default function SignUp() {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [cPassword, setCPassword] = useState('');
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const [errors, setErrors] = useState({
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    cPassword: '',
  });

  const handleValidation = () => {
    let isValid = true;
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

    // Validate First Name
    if (firstName.trim() === '') {
      setErrors((prevErrors) => ({ ...prevErrors, firstName: 'First Name is required' }));
      isValid = false;
    } else {
      setErrors((prevErrors) => ({ ...prevErrors, firstName: '' }));
    }

    // Validate Last Name
    if (lastName.trim() === '') {
      setErrors((prevErrors) => ({ ...prevErrors, lastName: 'Last Name is required' }));
      isValid = false;
    } else {
      setErrors((prevErrors) => ({ ...prevErrors, lastName: '' }));
    }

    // Validate Email
    if (!emailRegex.test(email)) {
      setErrors((prevErrors) => ({ ...prevErrors, email: 'Invalid email address' }));
      isValid = false;
    } else {
      setErrors((prevErrors) => ({ ...prevErrors, email: '' }));
    }

    // Validate Password
    if (password.length < 6) {
      setErrors((prevErrors) => ({ ...prevErrors, password: 'Password must be at least 6 characters' }));
      isValid = false;
    } else {
      setErrors((prevErrors) => ({ ...prevErrors, password: '' }));
    }

    // Validate Confirm Password
    if (cPassword !== password || cPassword.length < 6) {
      setErrors((prevErrors) => ({ ...prevErrors, cPassword: 'Password and Confirm Password does not match' }));
      isValid = false;
    } else {
      setErrors((prevErrors) => ({ ...prevErrors, cPassword: '' }));
    }

    return isValid;
  };

  const userSignUp = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (handleValidation()) {
      const url = BaseUrl() + 'api/user/register_web';
      const data = {
        firstName: firstName,
        lastName: lastName,
        email: email,
        password: password,
      };

      // try {
      //   const response = await axios.post(url, data);

      //   if (response.data.ResponseCode === 200) {
      //     localStorage.setItem('authorization', response.data.tempToken);
      //     localStorage.setItem('profile', JSON.stringify(response.data.Response));
      //     navigate('/register-otp-verify', {
      //       state: {
      //         data: data,
      //       },
      //     });
      //   }

      //   setLoading(false);

      //   if (response.data.ResponseCode === 500) {
      //     swal(response.data.ResponseMessage);
      //   }
      // }
      try {
        const response = await axios.post(url, data);
        console.log(response)
        if (response.status === 200) {
            localStorage.setItem("authorization", response.data.token);
            localStorage.setItem("profile", JSON.stringify(response.data.Response));
            navigate("/home", {
                state: {
                    data: response.data.Response
                }
            });
        }
        else {
            swal(response.data.ResponseMessage);
        }
    }  catch (error) {
        swal(error.response.data.ResponseMessage);
      }
    }
  };

  return (
    <div>
      <Header />
      <form onSubmit={userSignUp}>
        <div className="w-full max-w-2xl mx-auto md:mt-[408px]">
          <div className="bg-white shadow-md z-10 mx-3 absolute  rounded px-12 py-16 sm:-mt-96 md:-mt-64 mt-20 w-11/12 md:w-4/5 lg:w-2/5">
            <div className="mb-4">
              <input
                className={`appearance-none border-b-2 w-full py-2 px-3 border-gray-500 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${errors.firstName && 'border-red-500'}`}
                type="text"
                placeholder="First Name"
                value={firstName}
                onChange={(e) => {
                  setFirstName(e.target.value);
                  setErrors((prevErrors) => ({ ...prevErrors, firstName: '' })); 
                }}
              />
              <div className="mr-auto text-left px-2">
                {errors.firstName && <span className="text-red-500">{errors.firstName}</span>}
              </div>
            </div>
            <div className="mb-4">
              <input
                className={`appearance-none border-b-2 w-full py-2 px-3 text-gray-700 border-gray-500 leading-tight focus:outline-none focus:shadow-outline ${errors.lastName && 'border-red-500'}`}
                type="text"
                placeholder="Last Name"
                value={lastName}
                onChange={(e) => {
                  setLastName(e.target.value);
                  setErrors((prevErrors) => ({ ...prevErrors, lastName: '' }));
                }}
              />
              <div className="mr-auto text-left px-2">
                {errors.lastName && <span className="text-red-500">{errors.lastName}</span>}
              </div>
            </div>
            <div className="mb-4">
              <input
                className={`appearance-none border-b-2 w-full py-2 px-3 text-gray-700 border-gray-500 leading-tight focus:outline-none focus:shadow-outline ${errors.email && 'border-red-500'}`}
                type="text"
                placeholder="Email Id"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                  setErrors((prevErrors) => ({ ...prevErrors, email: '' })); 
                }}
              />
              <div className="mr-auto text-left px-2">
                {errors.email && <span className="text-red-500">{errors.email}</span>}
              </div>
            </div>
            <div className="mb-6">
              <input
                className={`appearance-none border-b-2 w-full py-2 px-3 border-gray-500 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${errors.password && 'border-red-500'}`}
                type="password"
                placeholder="Create Password"
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value);
                  setErrors((prevErrors) => ({ ...prevErrors, password: '' })); 
                }}
              />
              <div className="mr-auto text-left px-2">
                {errors.password && <span className="text-red-500">{errors.password}</span>}
              </div>
            </div>
            <div className="mb-6">
              <input
                className={`appearance-none border-b-2 w-full border-gray-500 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${errors.cPassword && 'border-red-500'}`}
                type="password"
                placeholder="Confirm Password"
                value={cPassword}
                onChange={(e) => {
                  setCPassword(e.target.value);
                  setErrors((prevErrors) => ({ ...prevErrors, cPassword: '' })); 
                }}
              />
              <div className="mr-auto text-left px-2">
                {errors.cPassword && <span className="text-red-500">{errors.cPassword}</span>}
              </div>
            </div>

            <div className="grid justify-items-center mt-4">
              <button onClick={userSignUp} className="bg-gradient-to-r to-cyan-500 from-blue-500 hover:bg-blue-700 text-white md:text-2xl font-bold py-3 mb-2 px-20 md:px-28 rounded-full">
                Sign Up
              </button>
              <div className="font-bold space-y-1 text-sm text-gray-500">
                <div className="flex">
                  <p>I have an account?</p>
                  <Link to="/login" className="text-blue-500">
                    Sign In
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
      {/* <Footer /> */}
    </div>
  );
}
