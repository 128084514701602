import React, { useState, useEffect } from "react"
import HOC from "../component/HOC"
import BaseUrl from "../BaseUrl";
import axios from 'axios'
import swal from "sweetalert";



const AddUser = () => {
    const [profile, setProfile] = useState([]);
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [mobile, setMobile] = useState('');
    const [password, setPassword] = useState('');
    const [image, setImage] = useState({ preview: "", raw: "" });
    

    const handleChange = e => {
        setImage({
            preview: URL.createObjectURL(e.target.files[0]),
            raw: e.target.files[0]
        });
    };


    const updateProfile = async (event) => {
        const token = localStorage.getItem("authorization")
        var auth = {
            headers: {
                authorization: `bearer ${token}`,
            },
        };
        event.preventDefault()
        const url = BaseUrl() + "api/user/add-user";
        const fd = new FormData();
        fd.append("firstName", firstName);
        fd.append("lastName", lastName);
        fd.append("mobile", mobile);
        fd.append("email", email);
        fd.append("password", password);
        fd.append("profile_pic", image.raw);
        try {
            const res = await axios.post(url, fd, auth);
            console.log("res>>>>>>>..", res)
            swal("Profile Update seccefully", {
                icon: "success"
            });
            if (res.data.ResponseCode == 200) {
                swal(res.data.ResponseMessage, {
                  icon: "success",
                });
            } else {
                swal(res.data.ResponseMessage)
            }
        } catch (err) {
            // console.log(err)
            swal(err.response.data.ResponseMessage, {
                icon: "error",
            });
        }
    };
    return (
        <>
            <div className="w-full max-w-xl mx-auto -mt-10">
                
                <form onSubmit={updateProfile}>
                    <div className="bg-white shadow-md rounded px-12 py-8">
                    <h1 className='text-400 mb-5 border-b-2 text-2xl'>Add User</h1>
                        <div className="justify-center mx-auto bg-gray-300 border4 rounded-full h-28 w-28">

                            {/*<img src = {BaseUrl()+profile.profile}  className="h-28 w-28 rounded-full border-2" /> */}
                            <label htmlFor="upload-button">
                                {image.preview ? (
                                    <img src={image.preview} alt="dummy" className="h-28 w-28 rounded-full border-2" />
                                ) : (
                                    <>
                                        <img src={BaseUrl() + "profile/d1.png"} role="button" className="h-28 w-28 rounded-full border-2" />
                                    </>
                                )}
                            </label>

                        </div>
                        <input
                            type="file"
                            id="upload-button"
                            className="hidden"
                            onChange={handleChange}
                            accept="image/*"
                        />
                        <div className="mb-4">
                            <input
                                className="capitalize placeholder-black appearance-none border-b-2 border-gray-500 w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                type="text"
                                placeholder="First Name"
                                required
                                value={firstName}
                                onChange={(e) => {
                                    setFirstName(e.target.value)
                                }}
                            />
                        </div>

                        <div className="mb-4">
                            <input
                                className="capitalize placeholder-black appearance-none border-b-2 border-gray-500 w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                type="text"
                                required
                                placeholder="Last Name"
                                value={lastName}
                                onChange={(e) => {
                                    setLastName(e.target.value)
                                }}
                            />
                        </div>
                        <div className="mb-4">

                            <input
                                className="capitalize placeholder-black appearance-none border-b-2 border-gray-500 w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                type="text"
                                required
                                placeholder="Mobile"
                                value={mobile}
                                onChange={(e) => {
                                    setMobile(e.target.value)
                                }}
                            />
                        </div>

                        <div className="mb-6">
                            <input
                                className="lowercase cursor-not-allowed  placeholder-black appearance-none border-b-2 border-gray-500 w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                type="text"
                                required
                                placeholder="Email Id"
                                value={email}
                                onChange={(e) => {
                                    setEmail(e.target.value)
                                }}
                            />
                        </div>

                        <div className="mb-6">
                            <input  
                                className="lowercase cursor-not-allowed  placeholder-black appearance-none border-b-2 border-gray-500 w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                type="text"
                                required
                                placeholder="Password"
                                value={password}
                                onChange={(e) => {
                                    setPassword(e.target.value)
                                }}
                            />
                        </div>
                        <div className='grid justify-items-start'>
                        </div>
                        <div className='grid justify-items-center mt-10'>
                            <button className='bg-gradient-to-r to-cyan-500 from-blue-500 hover:bg-blue-700 text-white text-xl font-bold py-1 mb-2 px-16 rounded-full customSwalBtn'>
                                Add
                            </button>

                        </div>
                    </div>
                </form>
            </div>

        </>
    )
}

export default HOC(AddUser)
