import logo from './logo.svg';
import './App.css';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom'
import Login from './component/Login'
import SignUp from './component/SignUp'
import CompanySignUp from './component/CompanySignUp'
import Landing from './component/Landing'
import StripePayment from './component/stripePayment'
import Home from './component/Home'
import AddGoal from "./component/AddGoal"
import AddUser from "./component/AddUser"
import UserList from "./component/UserList"
import EditUser from "./component/EditUser"
import ForgotPassword from "./component/ForgotPassword"
import ForgotPasswordOtp from "./component/OtpVerify"
import ChangePassword from "./component/ChangePassword"
import RegisterOtpVerify from "./component/RegisterOtpVerify"
import HelpCenter from './pages/HelpCenter'
import Report from "./pages/Reports"
import Profile from "./pages/Profile"
import MyGoalView from "./pages/MyGoal"
import About from "./pages/About"
import Footer from './component/Footer';
import NotFound from './pages/NotFound'
import PrivateRouter from './PrivateRouter'
import HOC from './component/HOC';
import TermsAndConditions from './component/TermsAndConditions';
import PrivacyPolicy from './component/PrivacyPolicy';
import PlanCard from './component/MyPlan';
import GoalListByCompany from "./component/GoalList"


function App() {
    return (
        <div className="App">
            <Router basename='serendipity-web'>
                <Routes >
                    <Route path="*" element={<NotFound />} />
                    <Route path="/" element={<Landing />} />
                    <Route path="/login" element={<Login />} />
                    <Route path="/signup" element={<SignUp />} />
                    <Route path="/company-signup" element={<CompanySignUp />} />
                    <Route path="/stripe" element={<StripePayment />} />
                    <Route path="/home" element={<PrivateRouter redirectTo="/"><Home /></PrivateRouter>} />
                    <Route path="/my-plan" element={<PrivateRouter redirectTo="/"><PlanCard /></PrivateRouter>} />
                    <Route path="/goal-list-company" element={<PrivateRouter redirectTo="/"><GoalListByCompany /></PrivateRouter>} />
                    
                    <Route path="/add-goal" element={<PrivateRouter redirectTo="/"><AddGoal /></PrivateRouter>} />
                    <Route path="/goal-list" element={<PrivateRouter redirectTo="/"><Home /></PrivateRouter>} />

                    <Route path="/add-user" element={<PrivateRouter redirectTo="/"><AddUser /></PrivateRouter>} />
                    <Route path="/user-list" element={<PrivateRouter redirectTo="/"><UserList /></PrivateRouter>} />
                    <Route path="/edit-user" element={<PrivateRouter redirectTo="/"><EditUser /></PrivateRouter>} />

                    <Route path="/help-center" element={<PrivateRouter redirectTo="/"><HelpCenter /></PrivateRouter>} />
                    <Route path="/my-report" element={<PrivateRouter redirectTo="/"><Report /></PrivateRouter>} />
                    <Route path="/my-profile" element={<PrivateRouter redirectTo="/"><Profile /></PrivateRouter>} />
                    <Route path="/my-goal" element={<PrivateRouter redirectTo="/"><MyGoalView /></PrivateRouter>} />
                    <Route path="/about-us" element={<About />} />
                    <Route path="/forgot-pass" element={<ForgotPassword />} />
                    <Route path="/otp-verify" element={<ForgotPasswordOtp />} />
                    <Route path="/change-password" element={<ChangePassword />} />
                    <Route path="/register-otp-verify" element={<RegisterOtpVerify />} />
                    <Route path="/hoc" element={<HOC />} />
                    <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
                    <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                </Routes>
            </Router>
        </div>
    );
}

export default App;
